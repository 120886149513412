import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box } from 'rebass/styled-components'
import CreatableSelect from 'react-select/creatable'
import * as algoliasearch from 'algoliasearch'

const SearchSelect = ({ dispatch, characteristics, source, target, onSelect, active=true, makeFilter=false, isClearable=false }) => {
    const algolia = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
    const index = algolia.initIndex(process.env.GATSBY_ALGOLIA_INDICE)
    const filter = { hitsPerPage: 1000, typoTolerance: 'min' }
    const [details, setDetails] = useState({ details: false, options: [], selected: []})
    const [activeState, setState] = useState(active)
    let timeOutId = null
    useEffect(() => {
        if (characteristics) {
            const options = []
            const value = (characteristics[target] && characteristics[target].number) || ''

            setDetails({ options, selected: { value, label: value }})
        }
    }, [characteristics])

    const createOption = (label, slug) => ({
        label,
        value: slug,
    })
    const handleCreate = (inputValue) => {
        const newOption = createOption(inputValue, inputValue)
        handleMultiChange(newOption)
    }

    const handleMultiChange = (newValue, actionMeta) => {
        onSelect(newValue)
        clearTimeout(timeOutId)
    }

    const handleSearch = (inputValue, actionMeta) => {
        if (timeOutId !== null) {
            clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => {
            if (inputValue) {
                let searchFilter = { ...filter }
                if(makeFilter) {
                    searchFilter.facetFilters = [`characteristics.model:${makeFilter}`]
                }
                index.search(inputValue, searchFilter).then(({ hits }) => {
                    const options = []
                    hits.forEach((doc) => {
                        const value = doc[source]
                        if (value) {
                            options.push({ value, label: value })
                        }
                    })
                    setDetails({ ...details, options })
                })
            }
        }, 500)
    }

    useEffect(() => {
        setState(active)
    }, [active])

    return (
        activeState ?
            <CreatableSelect
                isClearable={isClearable}
                closeMenuOnSelect={true}
                onInputChange={handleSearch}
                onChange={(newValue, actionMeta) => handleMultiChange(newValue, actionMeta)}
                onCreateOption={handleCreate}
                options={details.options}
                value={details.selected}
                placeholder="Type to Search..."
                noOptionsMessage={(input) => "Type to search or create..."}
            /> :
            (<Box>
                {
                    (Object.keys(details.selected).length > 1 && details.selected.label) ?
                        details.selected.label : 'None'
                }
            </Box>)
    )
}

function mapStateToProps(state) {
    return {
        characteristics: state.Pagebuilder.extras.characteristics,
    }
}

export default connect(mapStateToProps)(SearchSelect)
