import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass/styled-components'
import KeyPoints from '../KeyPoints'
import Characteristics from '../Characteristics'
import Description from '../Description'
import MediaRender from '../../components/general/MediaRender'

const IdentityTab = ({ dispatch, mediaSet }) => {
    const [media, setMedia] = useState()
    useEffect(() => {
        setMedia(mediaSet)
    }, [mediaSet])
    return (
        <Box fontSize='md'>
            {/* Removing large image as it hides too much info below (and the image is in the sidebar) - may revisit
            <Box mb='md'>
                {
                    media && media.length ?
                        <MediaRender file={media[0]} /> :
                        null
                }
            </Box> 
            */}
            <Flex mb='md' sx={{
                position: 'relative',
            }}>
                <Box width='35%' mr='lg'>
                    <Characteristics />
                </Box>
                <Box width='65%'>
                    <Box mb='sm'>
                        <KeyPoints />
                    </Box>
                </Box>
            </Flex>
            <Box sx={{ position: 'relative' }}>
                <Description />
            </Box>

        </Box>
    )
}

function mapStateToProps(state) {
    return {
        mediaSet: state.Pagebuilder.media.mediaSet,
    }
}
export default connect(mapStateToProps)(IdentityTab)
