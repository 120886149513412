import React from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components'

const Item = styled(Box)`
    border-left: 1px solid ${(props) => props.theme.colors.whiteOff};
    cursor: pointer;
    padding-right: 4px;
    .wrapper {
        border-left: 3px solid ${(props) => props.theme.colors.white};
    }
    &:hover, &.active {
        background-color: ${(props) => props.theme.colors.redOff};
        border-left: 1px solid ${(props) => props.theme.colors.red};
        .wrapper {
            border-left: 3px solid ${(props) => props.theme.colors.red};
        }
    }
`

const Tab = ({ children, name, active, ...rest }) => {
    return (
        <Item className={name === active ? 'active' : ''} {...rest}>
            <Box p='sm' className='wrapper' width='100%' height='100%'>
                {children}
            </Box>
        </Item>
    )
}

export default Tab
