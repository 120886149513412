import React from 'react'
import { Box } from 'rebass/styled-components'

const TabPanel = ({ children, name, active, ...rest }) => {
    return (
        <Box {...rest} width='100%' sx={{
            display: name === active ? 'block' : 'none',
        }}>
            {children}
        </Box>
    )
}

export default TabPanel
