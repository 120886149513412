import React from 'react'
import { connect } from 'react-redux'
import { Box, Button } from 'rebass/styled-components'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'

const OptionsTab = ({ dispatch, id }) => {
    const areYouSure = (id) => {
        if (id) {
            const confirmData = JSON.stringify({ isOpen: true, id, modalType:'DELETE_COLLECTION_ITEM', collection: null, message: 'This action is undoable and will remove all profiles, sales information, leads, history and media. Are you sure?' })
            dispatch(confirmBoxToggle(confirmData))
        }
    }
    return (
        <Box fontSize='md'>
            <Button bg='red' onClick={() => areYouSure(id)} sx={{
                borderColor: 'red'
            }}>Delete this Item</Button>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        id: state.Pagebuilder.doc,
    }
}
export default connect(mapStateToProps)(OptionsTab)
