import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import Select from 'react-select'

import { onSnapshot, collection, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const PriceCurrencySelect = ({ onInputChange, selectedValue, isClearable = false }) => {
    const [selected, setSelected] = useState({ price: '', currency: '', webDisplay: false })
    const [details, setDetails] = useState({ details: false, options: []})
    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'currencies'), orderBy('name', 'asc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const options = []
                snapshot.forEach((doc) => {
                    const val = doc.data()
                    val.id = doc.id
                    options.push({ value: val.id, label: val.symbol })
                })
                setDetails({ ...details, details: true, options })
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])

    useEffect(() => {
        setSelected(selectedValue)
    }, [details, selectedValue])

    const onPriceChange = (event) => {
        const price = event.target.value !== '' ? Number(event.target.value) : ''
        onInputChange({ ...selected, price })
    }

    const onWebDisplay = (event) => {
        const webDisplay = event.target.checked
        onInputChange({ ...selected, webDisplay })
    }

    const handleChange = (e) => {
        onInputChange({ ...selected, currency: e?.value ?? '' })
    }

    return (
        <Flex>
            <Box>
                <Input
                    type='number'
                    variant='inputSecondary'
                    name='price'
                    placeholder='0.00'
                    value={selected.price}
                    onChange={(e) => onPriceChange( e )}
                />
            </Box>
            <Box ml='xxs' minWidth='100px'>
                <Select
                    value={details.options.find((obj) => obj.value === selected.currency)}
                    options={details.options}
                    isClearable={isClearable}
                    onChange={handleChange}
                    width='100%'
                />
            </Box>
            <Flex alignItems='center' justifyContent='cnter' pl='sm'>
                <Text pr='xxs'>Show on Web?</Text>
                <input
                    type='checkbox'
                    name='webDisplay'
                    checked={selected.webDisplay}
                    onChange={(e) => onWebDisplay( e )}
                />
            </Flex>
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
    }
}

export default connect(mapStateToProps)(PriceCurrencySelect)
