import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { Box, Flex, Heading } from 'rebass/styled-components'
import { addItem } from '../../models/Collection'
import { setPageDetails, resetPageBuilder, setPageCharacteristics } from '../../system/redux/reducers/pagebuilder'
import { saveToggle, savingToggle } from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import PageLoader from '../../components/loaders/PageLoader'
import loadable from '@loadable/component'
import TabContainer from '../../components/tabs/TabContainer'
import IdentityTab from '../../modules/items/IdentityTab'
import SaleProfilesTab from '../../modules/items/SaleProfilesTab'
import WebProfilesTab from '../../modules/items/WebProfilesTab'
import HistoryTab from '../../modules/items/HistoryTab'
import MediaTab from '../../modules/items/MediaTab'
import NotesTab from '../../modules/NotesTab'
import { resetPageData } from '../../system/redux/reducers/page'

const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const CollectionAddView = ({ dispatch, pageTitle, pageParent, isSaving, isSetting }) => {
    const { register, handleSubmit, formState: { errors }} = useForm()
    const [tabData, setTabs] = useState([])

    useEffect(() => {
        dispatch(resetPageBuilder())
        
        // set defaults
        dispatch(setPageCharacteristics(JSON.stringify({ chassis: { visible: true }})))
        
        setTabs([
            {
                title: 'Identity',
                content: <IdentityTab />
            },
            {
                title: 'Web Profiles',
                content: <WebProfilesTab />
            },
            {
                title: 'Sale Profiles',
                content: <SaleProfilesTab />
            },
            {
                title: 'Ownership',
                content: <HistoryTab />
            },
            {
                title: 'Media',
                content: <MediaTab />
            },
            {
                title: 'Notes',
                content: <NotesTab type='item' />
            }
        ])

        return () => {
            dispatch(resetPageData())
            dispatch(resetPageBuilder())
        }
    }, [])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onSelectChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const constructPermalink = () => {
        let url = process.env.GATSBY_WEB_ADDRESS

        if (pageParent) {
            url += '/' + pageParent
        }

        return url += '/'
    }

    const onSubmit = () => {
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        addItem().then((docRef) => {
            dispatch(savingToggle(false))
            navigate(`/collection/edit/${docRef.data}`)
        })
    }
    return (
        <Box as='form' onSubmit={handleSubmit(() => {onSubmit() })} >
            <Flex alignItems="center" justifyContent="flex-start">
                <Heading variant="h4" as="h1" mr="lg" flexGrow={1}>
                    <EditableField
                        name="title"
                        placeholder='Enter Title'
                        {...register('title', { required: 'Cannot be empty' })}
                        type='text'
                        value={pageTitle}
                        variant="contentEditableInput"
                        onChange={(e) => onInputChange('title', e)}
                        pl="0"
                        errors={errors}
                        errorMessage="Cannot be empty"
                    />
                </Heading>
                {/* <Flex flexGrow={1} height="2px" bg="greyLighter"></Flex> */}
            </Flex>
            <Box>
                <Flex>
                    <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                        <Box variant="card" mt="sm" p="xs">
                            <TabContainer data={tabData} />
                        </Box>
                    </Flex>
                    {
                        isSetting ?
                            <ModuleSettings />
                            :
                            <RightSideBar onSelectChange={onSelectChange} onInputChange={onInputChange}  section="collection" />
                    }
                </Flex>
            </Box>
            {
                isSaving ?
                    <PageLoader />
                    : null
            }
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        pageTitle: state.Pagebuilder.details.title,
        pageParent: state.Pagebuilder.details.parent,
        auth: state.Auth.userVerified,
        isSaving: state.Savehandling.isSaving,
        profileId: state.Pagebuilder.profile.id,
        isSetting: state.Sidebar.isSetting,
    }
}
export default connect(mapStateToProps)(CollectionAddView)