import { doc, getDocs, getDoc, collection, query, where } from 'firebase/firestore'
import { db } from '../system/firebase/index'

// Collection Sale Details
export const transferFromProductSaleDetails = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('transferFromProductSaleDetails')()
    }
    return null
}

export const updateCollectionSaleDetailsData = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('updateCollectionSaleDetailsData')()
    }
    return null
}

export const addCollectionSaleDetails = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('addCollectionSaleDetails')(data)
    }
    return null
}

export const addCollectionSaleDetailsBatch = async (data) => {
    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('addCollectionSaleDetailsBatch')(data)
    }
    return null
}

export const getCollectionSaleDetails = async (id, whereArray = []) => {
    if (whereArray.length) {
        const q = query(collection(db, 'collectionSaleDetails'), where(whereArray[0], whereArray[1], whereArray[2]))
        const queryWhereSnapshot = await getDocs(q)

        return queryWhereSnapshot
    }
    const docRef = doc(db, 'collectionSaleDetails', id)
    const docSnap = await getDoc(docRef)
    return docSnap
}

export const addCollectionSaleDetailsSku = async (doc, id) => {
    let current = await getCollectionSaleDetails(doc)
    current = current.data()
    const data = { ...current }

    data.skus[id] = true

    if (typeof window !== 'undefined') {
        const { firebaseFunctionCall } = await import('../system/firebase/useFunctions')
        return firebaseFunctionCall('updateCollectionSaleDetails')({
            doc,
            data,
        })
    }
    return null
}
