import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const SaleProfleCategorySelect = ({ onInputChange, selectedValue }) => {
    const animatedComponents = makeAnimated();
    const [categories, setCategories] = useState(false)
    const [details, setDetails] = useState({ details: false, options: [], selected: []})

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'collectionSaleProfileCategories'), orderBy('category', 'asc'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    output.push(doc.data())
                })
                setCategories(output)
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])

    useEffect(() => {
        if (categories && !details.options?.length) {
            const options = []
            Object.entries(categories).map(([key, val]) => {
                options.push({ value: val.slug, label: val.category })
                return true
            })
            setDetails({ ...details, options })
        }
    }, [categories])


    useEffect(() => {
        if (details.options?.length && selectedValue) {
            const selected = []
            Object.entries(selectedValue).map(([key, category]) => {
                const item = details.options.filter((item) => item.value === category)
                selected.push(item[0])
                return true
            })
            setDetails({ ...details, details: selectedValue, selected })
        }
    }, [details.options, selectedValue])

    const handleMultiChange = async (type, newValue, actionMeta) => {
        const newList = []
        Object.entries(newValue).map(([key, item]) => {
            newList.push(item.value)
            return true
        })
        onInputChange(newList)
    }
    return (
        <Select
            isClearable={false}
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            onChange={(newValue, actionMeta) => handleMultiChange('categories', newValue, actionMeta)}
            options={details.options}
            value={details.selected}
        />
    )
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(SaleProfleCategorySelect)
