import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'

const QuickCollectionItemSearchBar = ({ search }) => {
    let timeOutId = null

    const doSearch = ( e ) => {
        clearTimeout(timeOutId)
        timeOutId = setTimeout(() => {
            search(e.target.value)
        }, 500)
    }

    return (
        <Box flexGrow='1'>
            <Flex mx='xs' justifyContent="center" sx={{
                position: 'relative',
                zIndex: '999',
            }}>
                <Input
                    id='search'
                    name='search'
                    type='text'
                    placeholder='Quick Search...'
                    variant='searchInput'
                    onChange={ doSearch }
                />
            </Flex>
        </Box>
    )
}

export default QuickCollectionItemSearchBar
