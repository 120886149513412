import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore'
import styled from 'styled-components'
import { db } from '../../system/firebase/index'
import Moment from 'react-moment'
import renderHTML from 'react-render-html'
import BorderContainer from '../../components/general/BorderContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
import Item from '../../components/rightSideBar/Item'
import ClientSelect from '../../components/selects/ClientSelect'
import UserSelect from '../../components/selects/UserSelect'
import DateSelect from '../../components/selects/DateSelect'
import PriceCurrencySelect from '../../components/selects/PriceCurrencySelect'
import EditableField from '../../components/forms/EditableField'
import Editor from '../../components/general/Editor'
import { addCollectionHistory, updateCollectionHistory } from '../../models/CollectionHistory'
import { getName } from '../../helpers/Clients'
import Pricing from '../../components/general/Pricing'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import Loading from '../../components/general/Loading'
import { Link } from 'gatsby'

const CheckBox = styled(Flex)`
   border: 2px solid ${(props) => props.theme.colors.greyLight};
   border-radius: 4px;
   cursor: pointer;
   height: 18px;
   width: 18px;
`

const HistoryForm = ({ onSubmit, doc, resetPanel }) => {
    const defaultForm = {
        owner: '',
        since: '',
        boughtPrice: { price: '', currency: '' },
        isCurrent: false, // will be correctly updated by cloud functions
        specialist: '',
        buyer: '',
        until: '',
        internal: true,
        commission: '0',
        costs: { price: '', currency: '' },
        soldPrice: { price: '', currency: '' },
        cleared: '',
        profit:  { price: '', currency: '' },
        received:  { price: '', currency: '' },
        notes: '',
    }
    const [loading, setLoading] = useState(false)
    const [internal, setInternal] = useState(true)
    const [addNewItemOnSave, setAddNewItemOnSave] = useState(false)
    const [inputs, setInputs] = useState(defaultForm)
    useEffect(() => {
        if (doc) {
            setLoading(false)
            setInputs({
                owner: doc.owner || inputs.owner,
                since: doc.purchase.date || inputs.since,
                boughtPrice: { price: doc.purchase.price, currency: doc.purchase.currency },
                isCurrent: doc.current || inputs.isCurrent,
                specialist: doc.sale.specialist || inputs.specialist,
                buyer: doc.sale.buyer || inputs.buyer,
                until: doc.sale.date || inputs.until,
                internal: doc.sale.internal || internal,
                commission: doc.sale.commission || inputs.commission,
                costs: doc.costs?.price ? { price: doc.costs.price, currency: doc.costs.currency } : inputs.costs,
                soldPrice: { price: doc.sale.price, currency: doc.sale.currency } || inputs.owner,
                cleared: doc.cleared || inputs.cleared,
                profit:  doc.profit?.price ? { price: doc.profit.price, currency: doc.profit.currency } : inputs.profit,
                received:  doc.received?.price ? { price: doc.received.price, currency: doc.received.currency } : inputs.received,
                notes: doc.notes || inputs.notes,
            })
        }
    }, [doc])
    const onInputChange = (key, value) => {
        setInputs({ ...inputs, [key]: value })
    }
    const onEditorChange = (value) => {
        setInputs({ ...inputs, notes: value })
    }
    const prepareHistorySubmit = async () => {
        setLoading(true)

        const data = {
            current: inputs.isCurrent,
            notes: inputs.notes,
            owner: inputs.owner,
            purchase: {
                currency: inputs.boughtPrice.currency,
                date: inputs.since !== '' ? (new Date(inputs.since)).getTime() : false,
                price: inputs.boughtPrice.price,
            },
            sale: {
                currency: inputs.soldPrice.currency,
                date: inputs.until !== '' ? (new Date(inputs.until)).getTime() : false,
                price: inputs.soldPrice.price,
                specialist: inputs.specialist,
                buyer: inputs.buyer,
                internal,
                commission: inputs.commission,
                costs: inputs.costs,
                cleared: inputs.cleared !== '' ? (new Date(inputs.cleared)).getTime() : false,
                profit: inputs.profit,
                received: inputs.received
            },
        }

        if (doc) {
            data.id = doc.id
        }

        await onSubmit(data)

        // if we're saving a new history item, also add that
        if(addNewItemOnSave) {
            const newItemData = {
                current: inputs.isCurrent,
                notes: '',
                owner: inputs.buyer,
                purchase: {
                    currency: inputs.soldPrice.currency,
                    date: inputs.until !== '' ? (new Date(inputs.until)).getTime() : false,
                    price: inputs.soldPrice.price,
                },
                sale: {
                    currency: '',
                    date: '',
                    price: '',
                    specialist: '',
                    buyer: '',
                    internal: true,
                    commission: '0',
                    costs: { price: '', currency: '' },
                    cleared: '',
                    profit: { price: '', currency: '' },
                    received: { price: '', currency: '' },
                },
            }
            
            await onSubmit(newItemData)
        }

        if (!doc) {
            setInputs(defaultForm)
            setLoading(false)
        } else {
            resetPanel()
        }
    }

    return (
        <BorderContainer mb='xxs' pb='xxxs'>
            {
                loading ?
                    <Loading /> :
                    <>
                        <Box pb='sm'>
                            <Box color='black'>
                                <Box sx={{
                                    position: 'relative',
                                    zIndex: '999',
                                }}>
                                    <Item title="Owner">
                                        <ClientSelect onInputChange={(value) => onInputChange('owner', value)} active={true} selectedValue={inputs.owner} />
                                        <Button variant='primaryTiny' mt='xxs' sx={{ backgroundColor: inputs.isCurrent ? 'green': 'red' }} onClick={() => onInputChange('isCurrent', !inputs.isCurrent)}>{ inputs.isCurrent ? 'Remove as Current Owner' : 'Set to Current Owner' }</Button>
                                    </Item>
                                    <Item title="Bought (date)">
                                        <DateSelect onInputChange={(value) => onInputChange('since', value)} selectedValue={inputs.since} />
                                    </Item>
                                    <Item title="Bought for (purchase price)">
                                        <PriceCurrencySelect onInputChange={(value) => onInputChange('boughtPrice', value)} selectedValue={inputs.boughtPrice} />
                                    </Item>
                                    {
                                        !inputs.isCurrent ?
                                            <>
                                                <Item title="Internal Sale">
                                                    <CheckBox onClick={() => setInternal(!internal)} alignItems="center" justifyContent="center" fontSize="10px">
                                                        { internal && <FontAwesomeIcon icon={faCheck} /> }
                                                    </CheckBox>
                                                </Item>
                                                <Item title="Buyer">
                                                    <ClientSelect onInputChange={(value) => onInputChange('buyer', value)} active={true} selectedValue={inputs.buyer} />
                                                </Item>
                                                <Item title="Specialist">
                                                    {
                                                        internal ?
                                                            <UserSelect item={inputs.specialist?.value || null} onSelectChange={(value) => onInputChange('specialist', value)} specialist={true} /> : 
                                                            <EditableField
                                                                name="dealer"
                                                                placeholder='Enter Dealer'
                                                                type='text'
                                                                value={inputs.specialist || ''}
                                                                variant="contentEditableInput"
                                                                onChange={(event) => onInputChange('specialist', event.target.value)}
                                                                pl="0"
                                                            />
                                                    }
                                                </Item>
                                                <Item title="Sold (date)">
                                                    <DateSelect onInputChange={(value) => onInputChange('until', value)} selectedValue={inputs.until} />
                                                </Item>
                                                <Item title="Sold for (sale price)">
                                                    <PriceCurrencySelect onInputChange={(value) => onInputChange('soldPrice', value)} selectedValue={inputs.soldPrice}/>
                                                </Item>
                                                {
                                                    internal ?
                                                        <>
                                                            <Item title="Sellers Commission">
                                                                <Flex alignItems='center'>
                                                                    <Box width='50px'>
                                                                        <EditableField
                                                                            name="commission"
                                                                            placeholder='0'
                                                                            type='text'
                                                                            value={inputs.commission || ''}
                                                                            variant="contentEditableInput"
                                                                            onChange={(event) => onInputChange('commission', event.target.value)}
                                                                            pl="0"
                                                                        />
                                                                    </Box>
                                                                    <Box>%</Box>
                                                                </Flex>
                                                            </Item>
                                                            <Item title="Total Costs">
                                                                <PriceCurrencySelect onInputChange={(value) => onInputChange('costs', value)} selectedValue={inputs.costs}/>
                                                            </Item>
                                                            <Item title="Profit from Sale">
                                                                <PriceCurrencySelect onInputChange={(value) => onInputChange('profit', value)} selectedValue={inputs.profit}/>
                                                            </Item>
                                                            <Item title="Amount back to Seller">
                                                                <PriceCurrencySelect onInputChange={(value) => onInputChange('received', value)} selectedValue={inputs.received}/>
                                                            </Item>
                                                            <Item title="Payment Cleared (date)">
                                                                <DateSelect onInputChange={(value) => onInputChange('cleared', value)} selectedValue={inputs.cleared} />
                                                            </Item>
                                                            <Item title="Create new History item?">
                                                                <CheckBox onClick={() => setAddNewItemOnSave(!addNewItemOnSave)} alignItems="center" justifyContent="center" fontSize="10px">
                                                                    { addNewItemOnSave && <FontAwesomeIcon icon={faCheck} /> }
                                                                </CheckBox>
                                                            </Item>
                                                        </>:
                                                        null
                                                }
                                            </> :
                                            null

                                    }
                                </Box>
                                <Button variant='primaryTiny' my='sm' onClick={() => onInputChange('isCurrent', !inputs.isCurrent)}>{ inputs.isCurrent ? 'Add Sold' : 'Set to Purchase Only' }</Button>
                                <Box pb='sm' fontWeight="semibold">Notes</Box>
                                <Box width='100%'>
                                    <Editor content={inputs.notes} onEditorChange={onEditorChange} />
                                </Box>
                            </Box>
                        </Box>
                        <Flex pb='sm' justifyContent='center'>
                            <Button variant='primarySmall' mb='sm' onClick={prepareHistorySubmit}>{ doc ? 'Update' : 'Save'} History</Button>
                        </Flex>
                    </>
            }
        </BorderContainer>
    )
}

const HistoryItem = ({ dispatch, doc, onSubmit }) => {
    const [edit, setEdit] = useState(false)
    const [history, setHistory] = useState(false)
    const owner = history && history.ownerData ? { name: getName(history.ownerData.first_name, history.ownerData.last_name, history.ownerData.company), id: history.ownerData.id } : ''

    const areYouSure = (id) => {
        const confirmData = JSON.stringify({ isOpen: true, id, modalType: 'DELETE_COLLECTION_HISTORY', collection: null })
        dispatch(confirmBoxToggle(confirmData))
    }
    const resetPanel = () => {
        setEdit(false)
    }
    useEffect(() => {
        if (doc) {
            if (doc.purchase.date && doc.purchase.date.seconds) {
                doc.purchase.date = (doc.purchase.date).toDate()
            }
            if (doc.sale.date && doc.sale.date.seconds) {
                doc.sale.date = (doc.sale.date).toDate()
            }
            if (doc.notes && Array.isArray(doc.notes)) {
                doc.notes = doc.notes[0] || ''
            }
            setHistory(doc)
        }
    }, [doc])
    return (
        history ?
            <Box bg='whiteOff' my='xxs' p='xs' sx={{
                position: 'relative',
            }}>
                <Box sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 'xs',
                    right: '40px',
                }} >
                    <Button variant='primaryTiny' onClick={() => setEdit(!edit)}>
                        { !edit ? 'Edit' : 'Lock'}
                    </Button>
                </Box>
                <Box sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 'xs',
                    right: 0,
                }} onClick={() => areYouSure(history.id)} mr='xs' fontSize='xxl' color='greyMedium'>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Box>
    
    
                <Box pt='md' sx={{
                    display: edit ? 'block' : 'none',
                }}>
                    <HistoryForm onSubmit={onSubmit} doc={history} resetPanel={resetPanel} />
                </Box>
                <Box sx={{
                    display: edit ? 'none' : 'block',
                }}>
                    <Box pb='xxs'>
                        {
                            owner ?
                                <Link to={`/clients/edit/${owner.id}`}>{owner.name}</Link>
                                : ''
                        }
                    </Box>
                    <Box pt="xs">
                        {
                            history.purchase.date ?
                                <>
                                    <Box>
                                        Owner from <Moment format="DD MMM YYYY" date={history.purchase.date} />
                                    </Box>
                                    <Box mt='xxs'>
                                        Bought for <Pricing currency={history.purchase.currency} amount={history.purchase.price} />
                                    </Box> 
                                </>
                                : <Text>No purchase information</Text>
                        }
                    </Box>
                    <Box pt='xs'>
                        {
                            history.current ?
                                <Text color='green' fontWeight='bold'>Current Owner</Text>
                                : <Text color='orange' fontWeight='bold' pb='xxs'>Previous Owner</Text>
                        }
                        {
                            history.sale.date ?
                                <Text>Sold on <Moment format="DD MMM YYYY" date={history.sale.date} /> for <Pricing currency={history.sale.currency} amount={history.sale.price} /></Text>
                                : null       
                        }
                    </Box>
                    <Box pt='xs'>
                        {
                            history.notes !== '' ? renderHTML(history.notes) : null
                        }
                    </Box>
                </Box>
            </Box>
            : null
    )
}

const HistoryTab = ({ dispatch, id, auth }) => {
    const [loading, setLoading] = useState(false)
    const [addNew, activateAdd] = useState(false)
    const [history, setHistory] = useState([])
    useEffect(() => {
        let isCancelled = false
        setLoading(true)
        const q = collection(db, 'clients')
        if (id) {
            onSnapshot(q, (querySnapshot) => {
                if (!isCancelled) {
                    const clientArray = {}
                    querySnapshot.forEach((doc) => {
                        clientArray[doc.id] = { ...doc.data(), id: doc.id }
                    })
    
                    const hq = query(collection(db, 'collectionHistory'), where('sku', '==', id), orderBy('purchase.date', 'desc'), orderBy('date_added', 'desc'))
                    onSnapshot(hq, (querySnapshot) => {
                        if (!isCancelled) {
                            const outputArray = []
                            querySnapshot.forEach((doc) => {
                                const historyData = doc.data()
                                historyData.id = doc.id
                                historyData.ownerData = clientArray[historyData.owner]
                                outputArray.push(historyData)
                            })
                            setHistory(outputArray)
                            setLoading(false)
                        }
                    })
                }
            })
        } else {
            setLoading(false)
        }

        return () => {
            isCancelled = true
        }
    }, [id])

    const onSubmit = async (content) => {
        setLoading(true)
        const data = {
            ...content,
            sku: id,
            edited_by: auth.uid,
        }
        if (content.id) {
            // We need to update
            const doc = content.id
            delete data.id
            await updateCollectionHistory(doc, data)
        } else {
            // Adding a new one
            data.added_by = auth.uid
            await addCollectionHistory(data)
            activateAdd(false)
        }
    }

    const addNewHistory = () => {
        activateAdd(true)
    }
    return (
        <Box fontSize='md'>
            <Box>
                {
                    addNew ?
                        <HistoryForm onSubmit={onSubmit} /> :
                        <Box sx={{
                            color: 'greyLight',
                            '&:hover': {
                                backgroundColor: 'redOff',
                                cursor: 'pointer',
                                color: 'greyMedium',
                            },
                        }}>
                            <BorderContainer mb='xxs' pb='xxxs'>
                                <Flex onClick={addNewHistory} width='100%' justifyContent='center' fontSize='xxxl'>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Flex>
                            </BorderContainer>
                        </Box>
                }
            </Box>
            {
                loading ?
                    <Box py='sm'>
                        <Loading />
                    </Box> :
                    history && history.length ?
                        Object.entries(history).map(([key, doc]) => {
                            return (
                                <HistoryItem key={doc.id} dispatch={dispatch} doc={doc} onSubmit={onSubmit} />
                            )
                        }) :
                        <Text>There is no history yet.</Text>
            }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        id: state.Pagebuilder.doc,
    }
}
export default connect(mapStateToProps)(HistoryTab)
