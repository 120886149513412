import React, { useState } from 'react'
import { connect } from 'react-redux'
import renderHTML from 'react-render-html'
import { Button, Flex, Heading, Box } from 'rebass/styled-components'
import Editor from '../components/general/Editor'
import { setPageExtras } from '../system/redux/reducers/pagebuilder'
import { saveToggle } from '../system/redux/reducers/savehandling'

const Description = ({ dispatch, description }) => {
    const [edit, setEdit] = useState(description ? false : true)
    const onEditorChange = (value) => {
        const extrasData = JSON.stringify({ description: value })
        dispatch(setPageExtras(extrasData))
        dispatch(saveToggle(true))
    }

    return (
        <>
            <Flex justifyContent='space-between'>
                <Heading pb='sm' variant="h4" as="h4">More about this item</Heading>
                <Button variant='primaryTiny' onClick={() => setEdit(!edit)}>
                    { !edit ? 'Edit' : 'Lock'}
                </Button>
            </Flex>
            {
                !edit && description ? renderHTML(description) : 
                    (<Box sx={{
                        position: 'relative',
                        zIndex: 0
                    }}>
                        <Editor content={description} onEditorChange={onEditorChange} />
                    </Box>)
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        description: state.Pagebuilder.extras.description,
    };
}

export default connect(mapStateToProps)(Description)
