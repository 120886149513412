import React, { useEffect, useState, useContext } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Flex, Text } from 'rebass/styled-components'
import { ThemeContext } from 'styled-components'
import PageBuilderHeader from '../PageBuilderHeader'
import PageBuilder from '../PageBuilder'
import BorderContainer from '../../components/general/BorderContainer'
import ModuleSelectBox from '../../components/pagebuilder/ModuleSelectBox'
import TemplateSelect from '../../components/selects/TemplateSelect'
import Select from 'react-select'
import { addWebProfile, setWebProfileActiveStatus } from '../../models/CollectionWebProfiles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { setProfileId } from '../../helpers/Pagebuilder'
import EditableField from '../../components/forms/EditableField'
import { setProfile } from '../../system/redux/reducers/pagebuilder'
import Loading from '../../components/general/Loading'

import { onSnapshot, collection, query, orderBy, where } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { navigate } from 'gatsby'

const WebProfilesTab = ({ dispatch, doc, profileActive, profileId, profileName, pageModules, auth }) => {
    const theme = useContext(ThemeContext);
    const [templateSelect, setTemplates] = useState({ setTemplate: false })
    const [profilesSelect, setProfilesSelect] = useState({ options: [], selected: []})
    const [profiles, setProfiles] = useState([])
    const [addProfile, setAddProfile] = useState(false)
    const [loading, setLoading] = useState(false)
    const colourStyles = {
        option: (provided, state) => {
            const styles = { ...provided }
            styles.cursor = 'pointer'
            styles.color = state.isDisabled ?
                undefined :
                state.isSelected ?
                    theme.colors.black :
                    state.isFocused ?
                        theme.colors.greyLighter :
                        theme.colors.greyLighter

            styles.backgroundColor = state.isDisabled ?
                undefined :
                state.isSelected ?
                    theme.colors.greyLight :
                    state.isFocused ?
                        theme.colors.greyLight :
                        undefined
            if (profiles.length) {
                let active
                Object.entries(profiles).map(([key, profile]) => {
                    if (profile.active) {
                        active = key
                    }
                })
                if (state.data.value === active) {
                    styles.color = theme.colors.green
                }
            }
            return styles
        },
    }

    useEffect(() => {
        let isCancelled = false
        if (doc && !addProfile) {
            
            const q = query(collection(db, 'collectionWebProfiles'), where('item_id', '==', doc), orderBy('date_added', 'desc'))
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    const output = []
                    snapshot.forEach((doc) => {
                        const data = doc.data()
                        data.id = doc.id
                        output.push(data)
                    })
                    setProfiles(output)
                }
            })
        }

        return () => {
            isCancelled = true
        }
    }, [doc])

    useEffect(() => {
        if (pageModules.length) {
            setTemplates({ setTemplate: false })
        }
    }, [pageModules])

    useEffect(() => {
        if (profiles.length && profileId && !addProfile) {
            const options = []
            const selected = []
            Object.entries(profiles).map(([key, wp]) => {
                options.push({ value: wp.id, label: typeof wp.profile === 'string' ? wp.profile : wp.profile.name })
                if (wp.id === profileId) {
                    selected.push({ value: wp.id, label: typeof wp.profile === 'string' ? wp.profile : wp.profile.name })
                }
            })
            setProfilesSelect({ options, selected })
        }
    }, [profiles, profileId, addProfile])

    useEffect(() => {
        if (addProfile) {
            const profileData = JSON.stringify({ active: profileActive, id: profileId, name: '' })
            dispatch(setProfile(profileData))
        }
    }, [addProfile])

    const onProfileSelectChange = async (newValue, actionMeta) => {
        let id = newValue.value
        // if (actionMeta.action === 'create-option') {
        //     // We are adding a new profile
        //     const newProfile = await addWebProfile({ id: profileId, profile: id }, auth)
        //     id = newProfile.data
        // }
        // setProfileId(dispatch, id)
        navigate(`/collection/edit/${id}`)
    }
    useEffect(() => {
        if (profileActive === true || profileActive === false) {
            setLoading(false)
        }
    }, [profileActive])

    const toggleActive = async () => {
        setLoading(true)
        await setWebProfileActiveStatus(profileId, !profileActive)
    }
    const onInputChange = (value) => {
        const profileData = JSON.stringify({ active: profileActive, id: profileId, name: value })
        dispatch(setProfile(profileData))
    }
    const onCreateWebProfile = async () => {
        setLoading(true)
        const newProfile = await addWebProfile({ id: profileId, profile: profileName }, auth)
        setProfileId(dispatch, newProfile.data)
        setAddProfile(false)
        setLoading(false)
        navigate(`/collection/edit/${newProfile.data}`)
    }

    return (
        <>
            <Flex justifyContent='space-between'>
                <Box variant="borderBottom" px="xs" pb="sm" width='100%'>
                    <Box fontWeight="semibold">Profile:</Box>
                    {
                        profiles.length && !addProfile ?
                            <Flex py="sm" alignItems='center' width='50%'>
                                <Flex width={2/4} alignItems='center'>
                                    <Button title='Add a new web profile' type="button" variant='primarySmall' onClick={() => setAddProfile(true)} mr='xxs' p='xs'>+</Button>
                                    <Box fontWeight="semibold" width={1/4} flexGrow={1}>
                                        <Select
                                            isClearable={false}
                                            closeMenuOnSelect={true}
                                            onChange={(newValue, actionMeta) => onProfileSelectChange(newValue, actionMeta)}
                                            options={profilesSelect.options || []}
                                            value={profilesSelect.selected || 'none'}
                                            styles={colourStyles}
                                        />
                                    </Box>
                                </Flex>
                                <Box width={1/4} color={profileActive ? 'green' : 'greyLight'} pl='xs'>

                                    {
                                        loading ?
                                            <Flex width='20px'><Loading size='md' /></Flex> :
                                            <>
                                                <Box onClick={toggleActive} sx={{
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        color: !profileActive ? 'green' : 'greyLight',
                                                    },
                                                }}>
                                                    <FontAwesomeIcon icon={faCheck} />
                                                    {profileActive ? 'Active' : 'Inactive'}
                                                </Box>
                                            </>
                                    }
                                    
                                </Box>
                            </Flex>:
                            <Flex py="sm" alignItems='center' pr='xs'>
                                <EditableField
                                    name="title"
                                    placeholder='Enter Web Profile Title'
                                    type='text'
                                    value={profileName || ''}
                                    variant="contentEditableInput"
                                    onChange={(event) => onInputChange(event.target.value)}
                                    pl="0"
                                />
                                {
                                    loading ?
                                        <Flex width='75px'><Loading size='md' /></Flex> :
                                        <Button title='Save web profile' type="button" variant='primarySmall' onClick={() => onCreateWebProfile()} pl='xs' pr='sm' bg='green'><FontAwesomeIcon icon={faCheck} /><Box as='span' pl='xxs'>Save</Box></Button>
                                }
                                
                            </Flex>
                    }
                </Box>
                { (pageModules && pageModules.length) ? <PageBuilderHeader /> : null }
            </Flex>

            <Box px='xs'>
                {
                    templateSelect.setTemplate ?
                        <Box p='xs' width='100%'>
                            <Text py="xs" as="p">In order to use Page Builder modules, please set a template below:</Text>
                            <Flex py="sm" alignItems='center'>
                                <Box fontWeight="semibold" width={1/3}>Template:</Box>
                                <Box width={2/3}>
                                    <TemplateSelect />
                                </Box>
                            </Flex>
                        </Box> :
                        <>
                            {
                                (pageModules && pageModules.length) ?
                                    <PageBuilder modules={pageModules} /> :
                                    <Box pr='xs' width='100%'>
                                        <BorderContainer>
                                            <ModuleSelectBox index='0' moduleKey='0' />
                                        </BorderContainer>
                                    </Box>
                            }
                        </>
                }
            </Box> 
        </>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        doc: state.Pagebuilder.doc,
        pageModules: state.Pagebuilder.pagebuilder.modules,
        profileActive: state.Pagebuilder.profile.active,
        profileId: state.Pagebuilder.profile.id,
        profileName: state.Pagebuilder.profile.name,
    }
}
export default connect(mapStateToProps)(WebProfilesTab)
