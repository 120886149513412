import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { Box, Button, Flex } from 'rebass/styled-components'
import Tab from './Tab'
import TabPanel from './TabPanel'
import PermissionWrapper from '../../hooks/PermissionWrapper'

const TabContainer = ({ data, id = false, addLeadButtons = false }) => {
    const [currentTab, setActiveTab] = useState('0')
    return (
        <>
            <Flex py="xs" sx={{
                borderColor: 'whiteOff',
                borderStyle: 'solid',
                borderWidth: '0 0 1px',
            }}>
                {
                    Object.entries(data).map(([key, tab]) => {
                        return <Tab key={key} name={key.toString()} active={currentTab} onClick={() => setActiveTab(key.toString())}>{tab.title}</Tab>
                    })
                }
                <PermissionWrapper tierId='areaLeads'>
                    { 
                        addLeadButtons ?
                            id ?
                                <Flex flexDirection='column' ml='auto'>
                                    <Button bg={'red'} mb="xxs" p={2} fontSize='sm' sx={{ flexGrow: 1, borderColor:'red' }} onClick={() => navigate('/leads/add', { state: { type: 'selling', profileId: id }})}>Add Selling Lead</Button>
                                    <Button bg={'red'} p={2} fontSize='sm' sx={{ flexGrow: 1, borderColor:'red' }} onClick={() => navigate('/leads/add', { state: { type: 'buying', profileId: id }})}>Add Buying Lead</Button>
                                </Flex>
                                : <Flex flexDirection='column' ml='auto'>
                                    <Button bg={'red'} mb="xxs" p={2} fontSize='sm' sx={{ flexGrow: 1, borderColor:'red' }} onClick={() => navigate('/leads/add')}>Add Lead</Button>
                                </Flex>
                            : null
                    }
                </PermissionWrapper>
            </Flex>
            <Box py="xs">
                {
                    Object.entries(data).map(([key, panel]) => {
                        return <TabPanel key={key} name={key.toString()} active={currentTab}>
                            {panel.content}
                        </TabPanel>
                    })
                }
            </Box>
        </>
    )
}

export default TabContainer
