import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { Text, Box, Flex, Button } from 'rebass/styled-components'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import makeAnimated from 'react-select/animated'
import { sortTable } from '../../system/redux/reducers/tables'
import Table from '../../components/tables/Table'
import TableSort from '../../components/tables/TableSort'
import Cell from '../../components/tables/Cell'
import Row from '../../components/tables/Row'
import EllipsisControls from '../../components/tables/EllipsisControls'
import EllipsisControlsItems from '../../components/tables/EllipsisControlsItems'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import CreatableSelect from 'react-select/creatable'
import { addCollectionCharacteristic, getMakes } from '../../models/CollectionCharacteristics'
import { setList } from '../../system/redux/reducers/list'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../components/general/Loading'
import { onSnapshot, collection, orderBy, where, query } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import EditControls from '../../components/tables/EditControls'
import CollectionMakeField from '../../components/forms/CollectionMakeField'

const CollectionMakesView = ({ dispatch, order, auth, list, lastVisible, refresh }) => {
    const { register, handleSubmit, formState: { errors }} = useForm()
    const animatedComponents = makeAnimated();
    const ID='MAKE'
    const [isAscending, setAscending] = useState(true)
    const template = '0.2fr 1fr 1.9fr 2.1fr 0.2fr 0.2fr'
    const [makesObject, setMakesObject] = useState(false)
    const [modelsObject, setModelsObject] = useState(false)
    const [makes, setMakes] = useState({})
    const [models, setModels] = useState({})
    const [variants, setVariants] = useState({})
    const [variantsObject, setVariantsObject] = useState(false)
    const [selectedModel, setSelectedModel] = useState([])
    const [selectedMake, setSelectedMake] = useState([])
    const [selectedVariant, setSelectedVariant] = useState([])
    const [newMake, setNewMake] = useState('')
    const [openAdd, setOpenAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reload, triggerReload] = useState(false)

    useEffect(() => {
        setLoading(true)
        if(makesObject && makesObject[0].id){
            fetchMoreData(true)
        }
    }, [makesObject, reload, refresh])

    // Getting the makes, models & variant document IDs
    useEffect(() => {
        let isCancelled = false
        const ma = query(collection(db, 'collectionCharacteristics'), where('slug', '==', 'makes'))
        onSnapshot(ma, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const saved = doc.data()
                    saved.id = doc.id
                    output.push(saved)
                })
                setMakesObject(output)
            }
        })
        const mo = query(collection(db, 'collectionCharacteristics'), where('slug', '==', 'models'))
        onSnapshot(mo, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const saved = doc.data()
                    saved.id = doc.id
                    output.push(saved)
                })
                setModelsObject(output)
            }
        })
        const va = query(collection(db, 'collectionCharacteristics'), where('slug', '==', 'variants'))
        onSnapshot(va, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const saved = doc.data()
                    saved.id = doc.id
                    output.push(saved)
                })
                setVariantsObject(output)
            }
        })
        setSelectedModel([])
        return () => {
            isCancelled = true;
        }
    }, [refresh, order])
    
    // Getting the individual makes
    useEffect(() => {
        let isCancelled = false
        if (makesObject) {
            Object.entries(makesObject).map(([key, doc]) => {
                const q = query(collection(db, 'collectionCharacteristics', doc.id, 'list'), orderBy('title'))
                onSnapshot(q, (snapshot) => {
                    if (!isCancelled) {
                        const options = []
                        snapshot.forEach((doc) => {
                            const make = doc.data()
                            make.id = doc.id
                            options.push(make)
                        })
                        setMakes(options)
                    }
                })
            })
        }
        return () => {
            isCancelled = true;
        }
    }, [makesObject])

    useEffect(() => {
        let isCancelled = false
        if (modelsObject) {
            Object.entries(modelsObject).map(([key, doc]) => {
                const q = query(collection(db, 'collectionCharacteristics', doc.id, 'list'), orderBy('title'))
                onSnapshot(q, (snapshot) => {
                    if (!isCancelled) {
                        const options = []
                        snapshot.forEach((doc) => {
                            const model = doc.data()
                            model.id = doc.id
                            options.push(model)
                        })
                        setModels(options)
                    }
                })
            })
        }

        return () => {
            isCancelled = true;
        }
    }, [modelsObject])

    useEffect(() => {
        let isCancelled = false
        if (variantsObject) {
            Object.entries(variantsObject).map(([key, doc]) => {
                const q = query(collection(db, 'collectionCharacteristics', doc.id, 'list'), orderBy('title'))
                onSnapshot(q, (snapshot) => {
                    if (!isCancelled) {
                        const options = []
                        snapshot.forEach((doc) => {
                            const variant = doc.data()
                            variant.id = doc.id
                            options.push(variant)
                        })
                        setVariants(options)
                    }
                })
            })
        }

        return () => {
            isCancelled = true;
        }
    }, [variantsObject])

    const openAddNew = () => {
        setOpenAdd(!openAdd)
    }
    const handleChange = (make, e) => {
        setNewMake(e.target.value)
    }
    const onSubmit = async () => {
        const data = {
            branch: makesObject[0].id,
            type: 'auto',
            content: {
                added_by: auth.uid,
                title: newMake,
                edited_by: auth.uid,
            },
        }
        setLoading(true)
        setOpenAdd(false)
        await addCollectionCharacteristic(data)
        triggerReload(!reload)
        setNewMake('')
        setLoading(false)
    }

    const areYouSure = (type, id) => {
        const confirmData = JSON.stringify({ isOpen: true, id, modalType: type, makesCollection: makesObject[0].id, modelsCollection: modelsObject[0].id, variantsCollection: variantsObject[0].id })
        dispatch(confirmBoxToggle(confirmData))
    }

    const handleCreate = async (type, inputValue, parent) => {
        const data = {
            type: 'auto',
            content: {
                added_by: auth.uid,
                title: inputValue,
                edited_by: auth.uid,
            },
        }
        type === 'model' ? data.content.make = parent : data.content.model = parent
        type === 'model' ? data.branch = modelsObject[0].id : data.branch =  variantsObject[0].id
        setLoading(true)
        await addCollectionCharacteristic(data)
        setLoading(false)
    }

    const onModelSelectChange = (name, event) => {
        const selectedArray = [...selectedModel]
        selectedArray.forEach((value, index) => {
            if (value.make === name) {
                selectedArray.splice(index, 1)
            }
        })

        selectedArray.push({ make: name, id: event.value, label: event.label, edit: false })
        setSelectedModel(selectedArray)

    }
    const openModelEdit = (dataID, label) => {
        const selectedArray = [...selectedModel]
        selectedArray.forEach((value, index) => {
            if (value.id === dataID) {
                selectedArray[index].edit = true
                if (label) {
                    selectedArray[index].label = label
                }
                setSelectedModel(selectedArray)
            }
        })
    }
    const closeModelEdit = (dataID) => {
        const selectedArray = [...selectedModel]
        selectedArray.forEach((value, index) => {
            if (value.id === dataID) {
                selectedArray[index].edit = false
                setSelectedModel(selectedArray)
            }
        })
    }
    const onVariantSelectChange = (name, event) => {
        const selectedArray = [...selectedVariant]
        selectedArray.forEach((value, index) => {
            if (value.make === name) {
                selectedArray.splice(index, 1)
            }
        })

        selectedArray.push({ model: name, id: event.value, label: event.label, edit: false })
        setSelectedVariant(selectedArray)
    }
    const openVariantEdit = (dataID, label) => {
        const selectedArray = [...selectedVariant]
        selectedArray.forEach((value, index) => {
            if (value.id === dataID) {
                selectedArray[index].edit = true
                if (label) {
                    selectedArray[index].label = label
                }
                setSelectedVariant(selectedArray)
            }
        })
    }
    const closeVariantEdit = (dataID) => {
        const selectedArray = [...selectedVariant]
        selectedArray.forEach((value, index) => {
            if (value.id === dataID) {
                selectedArray[index].edit = false
                setSelectedVariant(selectedArray)
            }
        })
    }

    const openMakeEdit = (data) => {
        const selectedArray = [...selectedMake]
        const result = selectedArray.find(({ id }) => id === data.id)
        if (result) {
            closeMakeEdit(data.id)
        } else {
            selectedArray.push({ id: data.id, label: data.title })
            setSelectedMake(selectedArray)
        }
    }
    const closeMakeEdit = (dataID) => {
        const selectedArray = [...selectedMake]
        selectedArray.forEach((value, index) => {
            if (value.id === dataID) {
                selectedArray.splice(index, 1)
                setSelectedMake(selectedArray)
            }
        })
    }

    const inputRef = useRef(null)

    const fetchMoreData = async (first = false, lastVisible) => {
        const perPage = 15
        let count = list.length / perPage
        let page = count
        let items = [...list]
        let last = lastVisible
        
        if (first) {
            count = perPage
            page = 0
            items = []
            last = false
        }

        const results = await getMakes(makesObject[0].id, page, perPage, last, order[ID])
        if (results.list) {
            Object.entries(results.list).map(([key, item]) => {
                items.push(item)
            })
            const listData = JSON.stringify({
                list: items,
                lastVisible: results.lastVisible,
            })
            dispatch(setList(listData))
            setLoading(false)
        }
    }

    const sortByColumn = (column) => {
        setAscending(!isAscending)
        const sortData = JSON.stringify({
            column,
            table: ID,
            direction: isAscending ? 'desc' : 'asc',
        })
        dispatch(sortTable(sortData))
    }

    const headerRow = [
        <Cell key="checkbox" />,
        <Cell key="make" onClick={ () => sortByColumn('title') }><Text>Make</Text><TableSort direction={ isAscending } /></Cell>,
        <Cell key="models"><Text>Models</Text></Cell>,
        <Cell key="total"><Text>Variants</Text></Cell>,
        <Cell key="actions"></Cell>,
    ]

    return (
        <Table title="Makes & Models" ID={ID} contentType="MAKE" addNew={openAddNew} template={template} headerRow={headerRow} actions={false}>
            <Box display={openAdd ? 'block' : 'none'} as='form' onSubmit={handleSubmit(() => {onSubmit() })}>
                <Box height='25px' />
                <Flex sx={{ alignItems: 'center', left:'40%', position: 'absolute', borderRadius: 4, p: 'xxs' }} >
                    <CollectionMakeField
                        name="make"
                        ref={inputRef}
                        width='225px'
                        type='text'
                        textAlign='center'
                        variant="makeEditableInput"
                        value={newMake}
                        placeholder='Add new make...'
                        {...register('make', { required: true })}
                        onChange={(e)=> handleChange('make', e)}
                        errors={errors}
                        errorMessage="Cannot be empty"/>
                    <Button onSubmit={onSubmit}>Add</Button>
                    <Box ml='xs' onClick={openAddNew} textDecoration='underline' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Cancel</Box>

                </Flex>
                <Box mt='25px' height='50px' sx={{ borderBottom:'2px solid rgba(183, 183, 183, 0.1)' }}></Box>
            </Box>
            {
                loading ?
                    <Box p='xs'><Loading /></Box> :
                    <InfiniteScroll
                        style={{ overflow: 'initial' }}
                        dataLength={list.length}
                        next={() => fetchMoreData(false, lastVisible)}
                        hasMore={makes.length > list.length ? true : false}
                        loader={makes.length > list.length ? <Box p='xs'><Loading /></Box> : null}
                    >
                        {
                            Object.entries(list).map(([key, make]) => {
                                const selectedMakeDetails = selectedMake.length ? selectedMake.find(({ id }) => id === make.id) : false
                                let selectedModelDetails = false
                                let selectedVariantDetails = false
                                let selectedModelOption = false
                                let selectedVariantOption = false
                                const modelOptions = []
                                const variantOptions = []
                                Object.entries(models).map(([key, model]) => {
                                    if (!selectedModelDetails) {
                                        selectedModelDetails = selectedModel.length ? selectedModel.find((makeModel) => makeModel.make === make.id) : false
                                    }
                                    if (model.make === make.id) {
                                        if (selectedModelDetails && selectedModelDetails.id === model.id) {
                                            selectedModelOption = { value: model.id, label: model.title }
                                        }
                                        modelOptions.push({ value: model.id, label: model.title })
                                    }
                                })
                                if (selectedModelDetails) {

                                    Object.entries(variants).map(([key, variant]) => {

                                        if (!selectedVariantDetails) {
                                            selectedVariantDetails = selectedVariant.length ? selectedVariant.find((modelVariant) => modelVariant.model === selectedModelDetails.id) : false
                                        }
                                        if ( selectedModelDetails.id === variant.model) {
                                            if (selectedVariantDetails && selectedVariantDetails.id === variant.id) {
                                                selectedVariantOption = { value: variant.id, label: variant.title }
                                            }
                                            variantOptions.push({ value: variant.id, label: variant.title })
                                        }
                                    })
                                }
                                return (
                                    <Row py='sm' template={template} key={key}>
                                        <Cell />
                                        <Cell fontWeight="bold">
                                            <Box>
                                                {
                                                    makesObject ?
                                                        <EditControls defaultValue={make.title} closeEdit={closeMakeEdit} data={selectedMakeDetails} branch={makesObject[0].id} /> : null
                                                }
                                            </Box>
                                        </Cell>
                                        <Cell>
                                            <Flex mr='xl' alignItems='flex-start'>
                                                {
                                                    selectedModelDetails && selectedModelDetails.edit ? 
                                                        <EditControls defaultValue={selectedModelDetails.label} closeEdit={closeModelEdit} data={selectedModelDetails} branch={modelsObject[0].id} /> :
                                                        <>
                                                            <Box width='300px'>
                                                                <CreatableSelect
                                                                    isClearable={false}
                                                                    closeMenuOnSelect={true}
                                                                    components={animatedComponents}
                                                                    onChange={(newValue) => onModelSelectChange(make.id, newValue)}
                                                                    onCreateOption={(inputValue) => handleCreate('model', inputValue, make.id)}
                                                                    options={modelOptions}
                                                                    placeholder='Start typing to add new...'
                                                                    sx={{ width: '60px' }}
                                                                    value={selectedModelOption}
                                                                /> 
                                                            </Box>
                                                            {
                                                                selectedModelDetails && selectedModelDetails.make === make.id ?
                                                                    <Box fontSize='14px' height='20px' ml='xs'>
                                                                        <Box onClick={()=> openModelEdit(selectedModelDetails.id, selectedModelOption.label)} sx={{ cursor: 'pointer' }}>
                                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                                        </Box>
                                                                        <Box color='red' sx={{ cursor: 'pointer' }} onClick={() => areYouSure('DELETE_MODEL', selectedModelDetails.id)}>
                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                        </Box>
                                                                    </Box>
                                                                    : null
                                                            }
                                                        </>
                                                }
                                            </Flex>
                                        </Cell>
                                        <Cell>
                                            <Flex mr='xl' alignItems='flex-start'>
                                                {
                                                    selectedModelDetails ?
                                                        selectedVariantDetails && selectedVariantDetails.edit ? 
                                                            <EditControls defaultValue={selectedVariantDetails.label} closeEdit={closeVariantEdit} data={selectedVariantDetails} branch={variantsObject[0].id} /> :
                                                            <>
                                                                <Box width='300px'>
                                                                    <CreatableSelect
                                                                        isClearable={false}
                                                                        closeMenuOnSelect={true}
                                                                        components={animatedComponents}
                                                                        onChange={(newValue) => onVariantSelectChange(selectedModelDetails.id, newValue)}
                                                                        onCreateOption={(inputValue) => handleCreate('variant', inputValue, selectedModelDetails.id)}
                                                                        options={variantOptions}
                                                                        placeholder='Start typing to add new...'
                                                                        sx={{ width: '60px' }}
                                                                        value={selectedVariantOption}
                                                                    /> 
                                                                </Box>
                                                                {
                                                                    selectedVariantDetails && selectedVariantDetails.model === selectedModelDetails.id ?
                                                                        <Box fontSize='14px' height='20px' ml='xs'>
                                                                            <Box onClick={()=> openVariantEdit(selectedVariantDetails.id, selectedVariantOption.label)} sx={{ cursor: 'pointer' }}>
                                                                                <FontAwesomeIcon icon={faPencilAlt} />
                                                                            </Box>
                                                                            <Box color='red' sx={{ cursor: 'pointer' }} onClick={() => areYouSure('DELETE_VARIANT', selectedVariantDetails.id)}>
                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                            </Box>
                                                                        </Box>
                                                                        : null
                                                                }
                                                            </>
                                                        : <Text>Please select a model</Text>
                                                }
                                            </Flex>
                                        </Cell>
                                        <Cell className="end">
                                            <Box>

                                                <EllipsisControls >
                                                    <EllipsisControlsItems onClick={() => openMakeEdit(make)}>{selectedMakeDetails ? 'Close Edit' : 'Edit'}</EllipsisControlsItems>
                                                    <EllipsisControlsItems onClick={() => areYouSure('DELETE_MAKE', make.id)}>Delete</EllipsisControlsItems>
                                                </EllipsisControls>
                                                <Box>
                                                    
                                                </Box>
                                            </Box>
                                        </Cell> 
                                        <Cell />
                                    </Row>
                                )
                            })
                        }
                    </InfiniteScroll>
            }
        </Table>
    )
}

function mapStateToProps(state) {
    return {
        order: state.Tables.sort,
        auth: state.Auth.userVerified,
        list: state.List.list,
        refresh: state.List.refresh,
        lastVisible: state.List.lastVisible,
    }
}

export default connect(mapStateToProps)(CollectionMakesView)
