import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box } from 'rebass/styled-components'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { setPageCharacteristics } from '../../system/redux/reducers/pagebuilder'
import { saveToggle } from '../../system/redux/reducers/savehandling'

const YearSelect = ({ dispatch, pageYear, active=true }) => {
    const animatedComponents = makeAnimated();
    const [details, setDetails] = useState({ details: false, options: []})
    const [activeState, setState] = useState(active)
    useEffect(() => {
        const year = (new Date()).getFullYear()
        const start = year - 1880
        const years = Array.from(new Array(start), ( val, index) => year - index)

        const options = []
        Object.entries(years).map(([key, val]) => {
            options.push({ value: val, label: val })
            return true
        })
        setDetails({ details: true, options })
    }, [pageYear])

    useEffect(() => {
        setState(active)
    }, [active])

    const handleMultiChange = async (type, newValue, actionMeta) => {
        const characteristicsData = JSON.stringify({ [type]: newValue.value })
        dispatch(setPageCharacteristics(characteristicsData))
        dispatch(saveToggle(true))
    }
    let output = 'None'
    if (pageYear) {
        output = pageYear
    }
    return (
        activeState && details.details && details.options.length ?
            <Select
                isClearable={false}
                closeMenuOnSelect={true}
                components={animatedComponents}
                onChange={(newValue, actionMeta) => handleMultiChange('year', newValue, actionMeta)}
                options={details.options}
                value={details.options.find((obj) => obj.value === pageYear)}
            /> :
            (<Box>
                {output}
            </Box>)
    )
}

function mapStateToProps(state) {
    return {
        pageYear: state.Pagebuilder.extras && state.Pagebuilder.extras.characteristics && state.Pagebuilder.extras.characteristics.year,
    }
}

export default connect(mapStateToProps)(YearSelect)
