import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Text } from 'rebass/styled-components'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import { addCollectionCharacteristic } from '../../models/CollectionCharacteristics'
import { setPageCharacteristics } from '../../system/redux/reducers/pagebuilder'
import { saveToggle } from '../../system/redux/reducers/savehandling'
import { generateSlug } from '../../helpers/Functions'
import { onSnapshot, collection, query, where, orderBy } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const VariantSelect = ({ dispatch, pageModel, pageVariant, auth, active=true }) => {
    const animatedComponents = makeAnimated();
    const [details, setDetails] = useState({ doc: '', details: false, options: [], selected: []})
    const [activeState, setState] = useState(active)
    const [variants, setVariants] = useState(false)

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'collectionCharacteristics'), where('slug', '==', 'variants'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const saved = doc.data()
                    saved.id = doc.id
                    output.push(saved)
                })
                setVariants(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    useEffect(() => {
        let isCancelled = false
        if (variants) {
            Object.entries(variants).map(([key, doc]) => {
                const q = query(collection(db, 'collectionCharacteristics', doc.id, 'list'), orderBy('title'))
                onSnapshot(q, (snapshot) => {
                    if (!isCancelled) {
                        const options = []
                        const selected = []
                        snapshot.forEach((doc) => {
                            const val = doc.data()
                            if (pageModel === val.model) {
                                options.push({ value: doc.id, label: val.title })
                                if (pageVariant && pageVariant === doc.id) {
                                    selected.push({ value: doc.id, label: val.title })
                                }
                            }
                        })
                        setDetails({ doc: doc.id, details: pageModel, options, selected })
                        if (pageVariant && !selected.length) {
                            const detailsData = JSON.stringify({ variant: '' })
                            dispatch(setPageCharacteristics(detailsData))
                        }
                    }
                })
            })
        }

        return () => {
            isCancelled = true
        }
    }, [pageModel, pageVariant, variants])

    useEffect(() => {
        setState(active)
    }, [active])

    const createOption = (label, slug) => ({
        label,
        value: slug,
    })
    const handleCreate = async (inputValue) => {
        if (pageModel) {
            const slug = generateSlug(inputValue)
            const data = {
                branch: details.doc,
                type: 'auto',
                content: {
                    added_by: auth.uid,
                    model: pageModel,
                    title: inputValue,
                    slug,
                    edited_by: auth.uid,
                },
            }
            const newVariant = await addCollectionCharacteristic(data)
            const newOption = createOption(inputValue, newVariant.data)
            onSelectChange('variant', newOption)
        }
    }

    const onSelectChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.value })
        dispatch(setPageCharacteristics(detailsData))
        dispatch(saveToggle(true))
    }
    let output = 'None'
    if (Object.keys(details.selected).length >= 1 && details.selected[0].label) {
        output = details.selected[0].label
    }
    return (
        activeState ?
            <>
                {
                    pageModel ?
                        <CreatableSelect
                            isClearable={false}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            onChange={(newValue) => onSelectChange('variant', newValue)}
                            onCreateOption={handleCreate}
                            options={details.options}
                            value={details.selected}
                        /> : <Text>Select Model First</Text>

                }
            </> :
            (<Box>
                {output}
            </Box>
            )
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        pageVariant: state.Pagebuilder.extras && state.Pagebuilder.extras.characteristics && state.Pagebuilder.extras.characteristics.variant,
        pageModel: state.Pagebuilder.extras && state.Pagebuilder.extras.characteristics && state.Pagebuilder.extras.characteristics.model,
    }
}

export default connect(mapStateToProps)(VariantSelect)
