import React, { useState, useEffect }  from 'react'
import { Box, Flex, Button } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import { updateCollectionCharacteristic } from '../../models/CollectionCharacteristics'
import Loading from '../general/Loading'

const EditControls = ({ data, branch, closeEdit, defaultValue }) => {
    const [value, setValue] = useState(defaultValue)
    const [loading, setLoading] = useState(false)

    useEffect(() =>{
        if (defaultValue) {
            setValue(defaultValue)
        }
    }, [defaultValue])
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const handleSubmit = (e) => {
        setLoading(true)
        const characteristicObject = {
            branch: branch,
            doc: data.id,
            data: {
                title: value
            }
        }
        updateCollectionCharacteristic(characteristicObject).then(() => {
            closeEdit(data.id)
            setLoading(false)
        })
    }
    return (
        data ? 
            <Flex justifyContent='center' alignItems='center' pr={1} width='100%'>
                <Input variant='input' mr={1} defaultValue={value} onChange={(e) => {handleChange(e)}}/>
                <Box width={1/3}>
                    {
                        loading ?
                            <Loading size='md' /> 
                            : <Button variant='primaryTiny' onClick={(e) => handleSubmit(e)}>Save</Button>
                    }
                </Box>
            </Flex> : value
    )
}

export default EditControls