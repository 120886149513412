import React, { forwardRef } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import styled from 'styled-components'
import TableInputError from '../tables/TableInputError'

const Container = styled(Flex)`
    position: relative;
    width: 100%;
`
const Message = styled(Box)`
    position: absolute;
    left: 15px;
    bottom: -15px;
`

const CollectionMakeField = (props, ref) => {
    const { errors, errorMessage, name, ...rest } = props
    return (
        <Container alignItems="center" justifyContent="flex-start" flexDirection="column">
            <Input height='35px' ref={ref} name={name} {...rest} />
            {(errors && errors[name]) && <Message><TableInputError message={errorMessage} /></Message>}
        </Container>
    )
}

export default forwardRef(CollectionMakeField)
