import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import {
    collection,
    getDocs,
    query,
    where,
    Timestamp,
    onSnapshot,
    orderBy,
} from 'firebase/firestore'
import styled from 'styled-components'
import { db } from '../../system/firebase/index'
import Moment from 'react-moment'
import Editor from '../../components/general/Editor'
import BorderContainer from '../../components/general/BorderContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../components/general/Loading'
import {
    addCollectionSaleProfile,
    updateCollectionSaleProfile,
} from '../../models/CollectionSaleProfiles'
import Item from '../../components/rightSideBar/Item'
import ClientSelect from '../../components/selects/ClientSelect'
import SaleProfileCategorySelect from '../../components/selects/SaleProfileCategorySelect'
import PriceCurrencySelect from '../../components/selects/PriceCurrencySelect'
import UserSelect from '../../components/selects/UserSelect'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import EditableField from '../../components/forms/EditableField'
import { setWebProfileSaleProfileId } from '../../models/CollectionWebProfiles'
import DateSelect from '../../components/selects/DateSelect'

const CheckBox = styled(Flex)`
    border: 2px solid ${(props) => props.theme.colors.greyLight};
    border-radius: 4px;
    cursor: pointer;
    height: 18px;
    width: 18px;
`

const SaleProfileForm = ({
    onSubmit,
    doc,
    resetPanel,
    id,
    linkedSaleProfileId,
}) => {
    const defaultForm = {
        title: '',
        seller: '',
        specialist: '',
        categories: [],
        salePrice: { price: '', currency: '', webDisplay: false },
        notes: '',
        history: '',
        storageLocation: '',
        storageDateIn: null,
        storageDateOut: null,
        carhuna: false,
    }
    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState(defaultForm)
    const [webProfiles, setWebProfiles] = useState(false)
    const [carhunaError, setCarhunaError] = useState(null)

    useEffect(() => {
        let isCancelled = false
        if (doc) {
            setLoading(false)
            if (!isCancelled) {
                setInputs({
                    title: doc.title || inputs.title,
                    seller: doc.sale.seller || inputs.seller,
                    specialist: doc.sale.specialist || inputs.specialist,
                    categories: doc.categories || inputs.categories,
                    salePrice: {
                        price: doc.sale.price,
                        currency: doc.sale.currency,
                        webDisplay: doc.sale.webDisplay,
                    },
                    notes: doc.notes || inputs.notes,
                    history: doc.history || inputs.history,
                    storageLocation: doc.storageLocation || inputs.storageLocation,
                    storageDateIn: doc.storageDateIn || inputs.storageDateIn,
                    storageDateOut: doc.storageDateOut || inputs.storageDateOut,
                })
                setChecked(doc.carhuna)
            }
        }

        if (doc?.id) {
            const q = query(
                collection(db, 'collectionWebProfiles'),
                where('saleProfileId', '==', doc.id),
                orderBy('date_added', 'desc')
            )
            onSnapshot(q, (snapshot) => {
                if (!isCancelled) {
                    const output = []
                    snapshot.forEach((doc) => {
                        const data = doc.data()
                        data.id = doc.id
                        output.push(data)
                    })
                    setWebProfiles(output)
                }
            })
        } else {
            setWebProfiles(false)
        }

        return () => {
            isCancelled = true
        }
    }, [doc])
    const onInputChange = (key, value) => {
        setInputs({ ...inputs, [key]: value })
        console.log(inputs)
    }
    const onEditorChange = (value) => {
        setInputs({ ...inputs, notes: value })
    }

    useEffect(() => {
        if (process.env.GATSBY_CLOUDINARY_NAME === 'girardo') {
            if (webProfiles) {
                const activeWebProfile = webProfiles.find(
                    (obj) => obj.active === true
                )
                if (
                    isChecked &&
                    !activeWebProfile.characteristics?.mileage?.value
                ) {
                    setChecked(false)
                    setCarhunaError(
                        'Please enter and save a mileage value in the Identity tab'
                    )
                }
            }
            if (carhunaError) {
                const timer = setTimeout(() => {
                    setCarhunaError(null)
                }, 5000)

                return () => clearTimeout(timer) // Cleanup function to clear the timer on component unmount
            }
        }
    }, [carhunaError, webProfiles])

    const [isChecked, setChecked] = useState(false)
    const handleCheck = () => {
        if (!webProfiles) {
            setChecked(false)
            setCarhunaError(
                'There must be an active Web Profile to send to Carhuna.'
            )
            return
        }
        const activeWebProfile = webProfiles.find((obj) => obj.active === true)
        if (!activeWebProfile) {
            setChecked(false)
            setCarhunaError(
                'There must be an active Web Profile to send to Carhuna.'
            )
            return
        }
        if (!activeWebProfile.characteristics?.mileage?.value) {
            setChecked(false)
            setCarhunaError(
                'Please enter and save a mileage value in the Identity tab'
            )
            return
        }
        setCarhunaError(null)
        setChecked(!isChecked)
    }

    const prepareProfileSubmit = async () => {
        setLoading(true)
        const data = {
            title: inputs.title,
            notes: inputs.notes,
            sale: {
                seller: inputs.seller,
                specialist: inputs.specialist,
                currency: inputs.salePrice.currency,
                price: inputs.salePrice.price,
                webDisplay: inputs.salePrice.webDisplay,
            },
            history: inputs.history,
            item_id: id,
            categories: inputs.categories,
            carhuna: isChecked,
            storageLocation: inputs.storageLocation,
            storageDateIn: inputs.storageDateIn,
            storageDateOut: inputs.storageDateOut,
        }
        if (doc) {
            data.id = doc.id
        }
        if (process.env.GATSBY_CLOUDINARY_NAME !== 'girardo') {
            delete data.carhuna
        }
        await onSubmit(data)
        if (!doc) {
            setInputs(defaultForm)
        } else {
            resetPanel()
        }
        setLoading(false)
    }

    const convertDateString = (date) => {
        return date ? { seconds: new Date(date).getTime() / 1000, nanoseconds: 0 } : null
    }

    const convertFirestoreDate = (firestoreDate) => {
        return firestoreDate ? new Date(firestoreDate.seconds * 1000) : null
    }

    return (
        <BorderContainer mb='xxs' pb='xxxs'>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Box pb='sm'>
                        <Box color='black'>
                            <Box
                                sx={{
                                    position: 'relative',
                                    zIndex: '999',
                                }}
                            >
                                {doc?.id ? (
                                    <>
                                        <Item title='Current'>
                                            <Text
                                                color={
                                                    doc?.id ===
                                                    linkedSaleProfileId
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            >
                                                {doc?.id === linkedSaleProfileId
                                                    ? 'Yes'
                                                    : 'No'}
                                            </Text>
                                        </Item>
                                        <Item
                                            title='Web Profiles'
                                            alignItems='initial'
                                        >
                                            {webProfiles &&
                                            webProfiles.length ? (
                                                <>
                                                    <Text>
                                                        <em>
                                                            The following web
                                                            profiles are
                                                            affected by changes
                                                            to this Sale
                                                            Profile:
                                                        </em>
                                                    </Text>
                                                    <ul>
                                                        {Object.entries(
                                                            webProfiles
                                                        ).map(
                                                            ([
                                                                key,
                                                                webProfile,
                                                            ]) => {
                                                                return (
                                                                    <li>
                                                                        <Text
                                                                            display='inline-block'
                                                                            key={
                                                                                webProfile.id
                                                                            }
                                                                        >
                                                                            {webProfile?.profile ??
                                                                                'Unknown'}
                                                                        </Text>
                                                                        <Text
                                                                            display='inline-block'
                                                                            pl='4px'
                                                                            color={
                                                                                webProfile?.active
                                                                                    ? 'green'
                                                                                    : 'red'
                                                                            }
                                                                        >
                                                                            (
                                                                            {webProfile?.active
                                                                                ? 'Active'
                                                                                : 'Inactive'}
                                                                            )
                                                                        </Text>
                                                                    </li>
                                                                )
                                                            }
                                                        )}
                                                    </ul>
                                                </>
                                            ) : (
                                                <Text>None</Text>
                                            )}
                                        </Item>
                                    </>
                                ) : null}
                                <Item title='Sale Profile Title'>
                                    <EditableField
                                        name='title'
                                        placeholder='Enter Sale Profile Title'
                                        type='text'
                                        value={inputs.title || ''}
                                        variant='contentEditableInput'
                                        onChange={(event) =>
                                            onInputChange(
                                                'title',
                                                event.target.value
                                            )
                                        }
                                        pl='0'
                                    />
                                </Item>
                                <Item title='Seller / Owner'>
                                    <ClientSelect
                                        isClearable={true}
                                        onInputChange={(value) =>
                                            onInputChange('seller', value)
                                        }
                                        active={true}
                                        selectedValue={inputs.seller}
                                    />
                                </Item>
                                <Item title='Specialist'>
                                    <UserSelect
                                        isClearable={true}
                                        item={inputs.specialist?.value || null}
                                        onSelectChange={(value) =>
                                            onInputChange(
                                                'specialist',
                                                value || ''
                                            )
                                        }
                                        specialist={true}
                                    />
                                </Item>
                                <Item title='Status'>
                                    <SaleProfileCategorySelect
                                        onInputChange={(value) =>
                                            onInputChange('categories', value)
                                        }
                                        selectedValue={inputs.categories}
                                    />
                                </Item>
                                <Item title='Asking Price'>
                                    <PriceCurrencySelect
                                        isClearable={true}
                                        onInputChange={(value) =>
                                            onInputChange('salePrice', value)
                                        }
                                        selectedValue={inputs.salePrice}
                                    />
                                </Item>
                                <Item title='Storage Location'>
                                    <EditableField
                                        name='storageLocation'
                                        placeholder='Enter Storage Location'
                                        type='text'
                                        value={inputs.storageLocation || ''}
                                        variant='contentEditableInput'
                                        onChange={(event) =>
                                            onInputChange(
                                                'storageLocation',
                                                event.target.value
                                            )
                                        }
                                        pl='0'
                                    />
                                </Item>
                                <Item title='Storage - Date In'>
                                    <DateSelect 
                                        onInputChange={(value) => onInputChange('storageDateIn', convertDateString(value))}
                                        selectedValue={convertFirestoreDate(inputs.storageDateIn)}
                                    />
                                </Item>
                                <Item title='Storage - Date Out'>
                                    <DateSelect 
                                        onInputChange={(value) => onInputChange('storageDateOut', convertDateString(value))}
                                        selectedValue={convertFirestoreDate(inputs.storageDateOut)}
                                    />
                                </Item>
                                {/* {process.env.GATSBY_CLOUDINARY_NAME ===
                                    'girardo' && (
                                    <>
                                        <Item title='Send to Carhuna'>
                                            <CheckBox
                                                alignItems='center'
                                                justifyContent='center'
                                                fontSize='10px'
                                                onClick={() => {
                                                    handleCheck()
                                                }}
                                            >
                                                {isChecked && (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                    />
                                                )}
                                            </CheckBox>
                                        </Item>
                                        {carhunaError && (
                                            <Box pt='sm'>
                                                <Text color='red'>
                                                    {' '}
                                                    {carhunaError}
                                                </Text>
                                            </Box>
                                        )}
                                    </>
                                )} */}
                            </Box>
                            {/* <Button variant='primaryTiny' mb='sm' onClick={() => onInputChange('isCurrent', !inputs.isCurrent)}>{ inputs.isCurrent ? 'Add Sold' : 'Set to Purchase Only' }</Button> */}
                            <Box py='sm' fontWeight='semibold'>
                                Notes
                            </Box>
                            <Box width='100%'>
                                <Editor
                                    content={inputs.notes}
                                    onEditorChange={onEditorChange}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Flex pb='sm' justifyContent='center'>
                        <Button
                            variant='primarySmall'
                            mb='sm'
                            onClick={prepareProfileSubmit}
                        >
                            {doc ? 'Update' : 'Save'} Sale Profile
                        </Button>
                    </Flex>
                </>
            )}
        </BorderContainer>
    )
}

const SaleProfileItem = ({
    dispatch,
    doc,
    onSubmit,
    id,
    profileCount,
    linkedSaleProfileId,
    setLoading,
    currentWebProfileId,
}) => {
    const [edit, setEdit] = useState(false)
    const areYouSure = (id) => {
        const confirmData = JSON.stringify({
            isOpen: true,
            id,
            modalType: 'DELETE_COLLECTION_SALES_PROFILE',
            collection: null,
        })
        dispatch(confirmBoxToggle(confirmData))
    }
    const resetPanel = () => {
        setEdit(false)
    }

    const linkToSaleProfile = (saleProfileId) => {
        setLoading(true)
        setWebProfileSaleProfileId(currentWebProfileId, saleProfileId).then(
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <Box
            bg='whiteOff'
            my='xxs'
            p='xs'
            sx={{
                position: 'relative',
            }}
        >
            {edit || doc?.id === linkedSaleProfileId ? null : (
                <Box
                    sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 'xs',
                        right: '100px',
                    }}
                >
                    <Button
                        variant='primaryTiny'
                        onClick={() => linkToSaleProfile(doc.id)}
                    >
                        Link
                    </Button>
                </Box>
            )}

            <Box
                sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 'xs',
                    right: '40px',
                }}
            >
                <Button variant='primaryTiny' onClick={() => setEdit(!edit)}>
                    {!edit ? 'Edit' : 'Lock'}
                </Button>
            </Box>
            {/*
                // FIXME: now how do we decide which ones can be deleted? if not attached to ANY web profiles? or any can be deleted?
                <Box sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 'xs',
                    right: 0,
                }} onClick={() => areYouSure(doc.id)} mr='xs' fontSize='xxl' color='greyMedium'>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Box>
            */}

            {edit ? (
                <Box pt='md'>
                    <SaleProfileForm
                        onSubmit={onSubmit}
                        doc={doc}
                        resetPanel={resetPanel}
                        id={id}
                        linkedSaleProfileId={linkedSaleProfileId}
                    />
                </Box>
            ) : (
                <Box>
                    <Flex>
                        <Box pb='xxs' pr='xs'>
                            <strong>{doc.title}</strong>
                        </Box>
                        <Box pr='xs'>
                            <strong>Created:</strong>{' '}
                            <Moment
                                format='DD MMM YYYY'
                                date={new Timestamp(
                                    doc.date_added.seconds,
                                    doc.date_added.nanoseconds
                                ).toDate()}
                            />
                        </Box>
                        <Box pr='xs'>
                            <strong>Price:</strong> {doc.sale.price}
                        </Box>
                        {doc?.id === linkedSaleProfileId ? (
                            <Box color='green'>
                                <strong>Linked</strong>
                            </Box>
                        ) : null}
                    </Flex>
                </Box>
            )}
        </Box>
    )
}

const SaleProfilesTab = ({
    dispatch,
    id,
    auth,
    linkedSaleProfileId,
    currentWebProfileId,
}) => {
    const [loading, setLoading] = useState(false)
    const [addNew, activateAdd] = useState(false)
    const [profiles, setProfiles] = useState([])

    useEffect(() => {
        let isCancelled = false

        if (id) {
            setLoading(true)
            const q = query(
                collection(db, 'collectionSaleProfiles'),
                where('item_id', '==', id),
                orderBy('date_added', 'desc')
            )
            onSnapshot(q, (querySnapshot) => {
                if (!isCancelled) {
                    const outputArray = []
                    querySnapshot.forEach((doc) => {
                        const saleData = doc.data()
                        saleData.id = doc.id
                        outputArray.push(saleData)
                    })
                    setProfiles(outputArray)
                    setLoading(false)
                }
            })
        }

        return () => {
            isCancelled = true
        }
    }, [id])

    const onSubmit = async (content) => {
        const data = {
            ...content,
            edited_by: auth.uid,
        }
        if (content.id) {
            // We need to update
            const doc = content.id
            delete data.id
            await updateCollectionSaleProfile(doc, data)
        } else {
            // Adding a new one
            data.added_by = auth.uid
            await addCollectionSaleProfile(data)
            activateAdd(!addNew)
        }
    }

    const addNewProfile = () => {
        activateAdd(!addNew)
    }
    return (
        <Box fontSize='md'>
            <Box>
                {addNew ? (
                    <SaleProfileForm onSubmit={onSubmit} id={id} />
                ) : (
                    <Box
                        sx={{
                            color: 'greyLight',
                            '&:hover': {
                                backgroundColor: 'redOff',
                                cursor: 'pointer',
                                color: 'greyMedium',
                            },
                        }}
                    >
                        <BorderContainer mb='xxs' pb='xxxs'>
                            <Flex
                                onClick={addNewProfile}
                                width='100%'
                                justifyContent='center'
                                fontSize='xxxl'
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Flex>
                        </BorderContainer>
                    </Box>
                )}
            </Box>
            {loading ? (
                <Box py='sm'>
                    <Loading />
                </Box>
            ) : profiles.length ? (
                Object.entries(profiles).map(([key, doc]) => {
                    return (
                        <SaleProfileItem
                            key={doc.id}
                            dispatch={dispatch}
                            doc={doc}
                            onSubmit={onSubmit}
                            id={id}
                            linkedSaleProfileId={linkedSaleProfileId}
                            currentWebProfileId={currentWebProfileId}
                            profileCount={profiles.length}
                            setLoading={setLoading}
                        />
                    )
                })
            ) : (
                <Text>There are no Sales Profiles yet.</Text>
            )}
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        auth: state.Auth.userVerified,
        id: state.Pagebuilder.doc,
        currentWebProfileId: state.Pagebuilder.profile.id,
        linkedSaleProfileId: state.Pagebuilder.details.saleProfileId,
    }
}
export default connect(mapStateToProps)(SaleProfilesTab)
