import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Heading } from 'rebass/styled-components'
import { db } from '../../system/firebase/index'
import { getDoc, doc } from 'firebase/firestore'
import InfiniteScroll from 'react-infinite-scroll-component'
import CollectionCard from '../../components/cards/CollectionCard'
// import CollectionSorting from '../../components/general/CollectionSorting'
import { getCollectionList } from '../../helpers/ListHandler'
import { setList, resetList } from '../../system/redux/reducers/list'
import { resetPageBuilder } from '../../system/redux/reducers/pagebuilder'
import Loading from '../../components/general/Loading'
import QuickCollectionItemSearchBar from '../../components/searchBar/QuickCollectionItemSearchBar'
import { CollectionCardStyles } from '../../helpers/Enums'

const CollectionView = ({ dispatch, PageInfo, category, list, listPage, listNbPages, order, term, categoryData }) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (order && order.length) {
            setLoading(true)
            fetchMoreData({ first: true })
        }
    }, [order])

    useEffect(() => {
        if (category) {
            dispatch(resetList())
            dispatch(resetPageBuilder())
            setLoading(true)
        }
        fetchMoreData({ first: true, category })
    },[category])

    const appendItemData = async (profileId) => {
        const docRef = doc(db, 'collectionWebProfiles', profileId.data)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists) {
            const items = [...list]
            const profile = { ...docSnap.data() }
            profile.id = docSnap.id
            items.unshift(profile)
            const listData = JSON.stringify({
                list: items,
            })
            dispatch(setList(listData))
        }
    }

    const fetchMoreData = async ({ page = 0, term = false, category = false, first = false }) => {
        const perPage = 25
        let items = [...list]
        
        // reset items on new fetch
        if (first) items = []

        // create algolia params
        const params = { page, perPage, term, category: category ? [category] : false }
        if(category === 'available') params.sortingReplica = 'sale.price-desc' // available cars should be sorted by sale price
        if(category === 'database') params.sortingReplica = 'date_modified-desc' // database cars should be sorted by date_modified

        // get the results
        let results = []
        results = await getCollectionList(params)

        // parse results, add new list items to existing list items, and store new list params
        if (results.list) {
            Object.entries(results.list).map(([key, item]) => {
                items.push(item)
            })
            const listData = JSON.stringify({
                list: items,
                term,
                page: results.page,
                nbPages: results.nbPages,
            })
            dispatch(setList(listData))
            setLoading(false)
        }
    }

    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box px='sm' pb='sm'>
                    <Heading as="h2" variant="h4" color="greyMedium">{(categoryData && categoryData.title) || PageInfo.page} Collection</Heading>
                </Box> 
                <Box mb='xs'>
                    <QuickCollectionItemSearchBar search={(term) => { fetchMoreData({ first: true, term, category }) }} />
                </Box>
            </Box>
            <Box mx='xs' mb='xs z'>
                {/* <CollectionSorting /> */}
            </Box>
            {
                loading ?
                    <Box p='xs'><Loading /></Box> :
                    
                    list.length ?
                        <InfiniteScroll
                            style={{ overflow: 'initial' }}
                            dataLength={list.length}
                            next={() => fetchMoreData({ page: listPage + 1, term, category })}
                            hasMore={listPage < listNbPages - 1}
                            loader={(listPage < listNbPages - 1) ? <Box p='xs'><Loading /></Box> : null}
                        >
                            <Box px="xs" pb='xs' sx={{
                                display: 'grid',
                                gridGap: 'md',
                                gridTemplateColumns: ['1fr', '1fr', '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
                            }}>
                                {
                                    Object.entries(list).map(([key, item]) => {
                                        return <CollectionCard appendItemData={appendItemData} options={item} key={item.id} cardStyle={category === 'storage' ? CollectionCardStyles.STORAGE : CollectionCardStyles.DEFAULT} />
                                    })
                                }
                            </Box>
                        </InfiniteScroll> : <Flex width='100%' justifyContent='center' alignItems='center' py='md'>No Results{category ? ' for ' + category : null}.</Flex>
                    
            }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        list: state.List.list,
        listPage: state.List.page,
        listNbPages: state.List.nbPages,
        order: state.List.order,
        term: state.List.term,
        categoryData: state.Page.category,
    }
}
export default connect(mapStateToProps)(CollectionView)
