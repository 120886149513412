import React from 'react'
import { connect } from 'react-redux'
import { Box } from 'rebass/styled-components'
import Gallery from '../Gallery'

const MediaTab = ({ dispatch, pageMedia }) => {
    return (
        <>
            <Box mt="sm">
                <Gallery title="Image Gallery" media={pageMedia.galleryImage} type="images" />
            </Box>
            <Box mt="sm">
                <Gallery title="Video Gallery" media={pageMedia.galleryVideo} type="videos" />
            </Box>
            <Box mt="sm">
                <Gallery title="Docs" media={pageMedia.galleryDocs} type="docs" />
            </Box>
        </>
    )
}

function mapStateToProps(state) {
    return {
        pageMedia: state.Pagebuilder.media,
    }
}
export default connect(mapStateToProps)(MediaTab)
