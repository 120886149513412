import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import { CollectionSection } from '../system/settings/pageData'
import { connect } from 'react-redux';
import CollectionView from '../views/collection/index'
import CollectionEditView from '../views/collection/modify'
import CollectionTransferView from '../views/collection/transfer'
import CollectionAddView from '../views/collection/add'
import CollectionCategories from '../views/collection/categories'
import CollectionMakes from '../views/collection/makes'

import Pointer from '../views/pointer'

const PageInfo = {
    parent: CollectionSection.section,
    page: 'All',
    icon: CollectionSection.icon,
}

const CollectionRoutes = () => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'Transfer' }} Component={CollectionTransferView} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/transfer')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={CollectionEditView} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/edit/:id')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Add New' }} Title='Add New' Component={CollectionAddView} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/add')} id='' />
            <Pointer PageInfo={{ ...PageInfo, page: 'Categories' }} Component={CollectionCategories} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/categories')} category='categories'/>
            <Pointer PageInfo={{ ...PageInfo, page: 'Makes & Models' }} Component={CollectionMakes} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/makes')} category='makes'/>
            <Pointer PageInfo={{ ...PageInfo, page: 'Category' }} Component={CollectionView} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection/:category')} />
            <Pointer PageInfo={PageInfo} Component={CollectionView} minimumRole={CollectionSection.minimumRole} path={withPrefix('/collection')} />
        </Router>
    )
}
function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(CollectionRoutes)
