import React from 'react'
import { Box, Heading } from 'rebass/styled-components'
import { transferFromProducts, updateCollectionItemTimestamp, transferToCollectionSearch, transferMediaFromWebProfileToCollection, linkSaleProfilesToWebProfiles } from '../../models/Collection'
import { transferFromProductCategories, updateCollectionCategoryData } from '../../models/CollectionCategories'
import { transferFromProductCharacteristics, updateCollectionCharacteristicsData, updateCollectionCharacteristicsBatch } from '../../models/CollectionCharacteristics'
import { transferFromProductHistory, updateCollectionHistoryData, updateCollectionHistoryCurrent, fixAllCollectionHistoryDates } from '../../models/CollectionHistory'
import { transferFromProductSaleDetails, updateCollectionSaleDetailsData } from '../../models/CollectionSaleDetails'
import { updateWebProfileMakeModelBatch, updateWebProfileTimestamp, backupCollectionWebProfileMediaSetToLegacyField } from '../../models/CollectionWebProfiles'
import { transferFromWebToSaleProfiles } from '../../models/CollectionSaleProfiles'


const CollectionView = ({ dispatch }) => {
    return (
        <Box variant="card" pt="lg" mt="sm">
            <Box mb='md' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Box px='xs' pb='xs'>
                    <Heading as="h2" variant="h4" color="greyMedium">Transfer Products</Heading>
                </Box>
            </Box>

            <Box onClick={transferFromWebToSaleProfiles}>transferFromWebToSaleProfiles</Box>
            <Box onClick={transferFromProductCategories}>transferFromProductCategories</Box>
            <Box onClick={transferFromProductCharacteristics}>transferFromProductCharacteristics</Box>
            <Box onClick={transferFromProductHistory}>transferFromProductHistory</Box>
            <Box onClick={transferFromProductSaleDetails}>transferFromProductSaleDetails</Box>
            <Box onClick={transferFromProducts}>transferFromProducts</Box>

            <Box mt='md' onClick={transferMediaFromWebProfileToCollection}>transferMediaFromWebProfileToCollection</Box>

            <Box mt='md' onClick={linkSaleProfilesToWebProfiles}>linkSaleProfilesToWebProfiles</Box>

            <Box mt='md' onClick={transferToCollectionSearch}>transferToCollectionSearch</Box>

            <Box mt='md' onClick={updateCollectionHistoryData}>updateCollectionHistoryData</Box>
            <Box onClick={updateCollectionSaleDetailsData}>updateCollectionSaleDetailsData</Box>
            <Box onClick={updateCollectionCategoryData}>updateCollectionCategoryData</Box>
            <Box onClick={updateCollectionCharacteristicsData}>updateCollectionCharacteristicsData</Box>
            <Box onClick={updateCollectionCharacteristicsBatch}>updateCollectionCharacteristicsListsBatch</Box>

            <Box mt='md' onClick={updateWebProfileMakeModelBatch}>updateWebProfileMakeModelBatch</Box>
            <Box mt='md' onClick={updateCollectionItemTimestamp}>updateCollectionItemTimestamp</Box>
            <Box onClick={updateWebProfileTimestamp}>updateWebProfileTimestamp</Box>

            <Box mt='md' onClick={updateCollectionHistoryCurrent}>updateCollectionHistoryCurrent</Box>

            <Box mt='md' onClick={backupCollectionWebProfileMediaSetToLegacyField}>backupCollectionWebProfileMediaSetToLegacyField</Box>

            <Box mt='md' onClick={fixAllCollectionHistoryDates}>fixAllCollectionHistoryDates</Box>
        </Box>
    )
}

export default CollectionView
