import React, { useEffect, useState, useContext } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box, Flex, Link, Heading, Text } from 'rebass/styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { updateItem } from '../../models/Collection'
import { updateActiveWebProfile } from '../../models/CollectionWebProfiles'
import { setProfileData } from '../../helpers/Pagebuilder'
import { setPBDoc, setPageDetails, setPageExtras, resetPageBuilder } from '../../system/redux/reducers/pagebuilder'
import { saveToggle, savingToggle } from '../../system/redux/reducers/savehandling'
import EditableField from '../../components/forms/EditableField'
import PageLoader from '../../components/loaders/PageLoader'
import loadable from '@loadable/component'
import TabContainer from '../../components/tabs/TabContainer'
import IdentityTab from '../../modules/items/IdentityTab'
import WebProfilesTab from '../../modules/items/WebProfilesTab'
import SaleProfilesTab from '../../modules/items/SaleProfilesTab'
import HistoryTab from '../../modules/items/HistoryTab'
import MediaTab from '../../modules/items/MediaTab'
import NotesTab from '../../modules/NotesTab'
import OptionsTab from '../../modules/items/OptionsTab'
import LeadsTab from '../../modules/items/LeadsTab'
import Loading from '../../components/general/Loading'

import { doc, getDoc, onSnapshot, collection, where, query , getDocs } from 'firebase/firestore'
import { db } from '../../system/firebase/index'
import { resetPageData } from '../../system/redux/reducers/page'
import { isGrantedAccess } from '../../helpers/Permissions'

const RightSideBar = loadable(() => import('../../modules/RightSideBar'))
const ModuleSettings = loadable(() => import('../../modules/ModuleSettings'))

const CollectionModify = ({ dispatch, id, auth, pageDetails, isSaving, profileId, isSetting, characteristics, permissionsTier }) => {
    const { register, errors,  } = useForm()
    const [tabData, setTabs] = useState([])
    const { store } = useContext(ReactReduxContext)
    const [collectionData, setCollection] = useState(false)
    const [profile, setProfile] = useState(false)
    const [saleProfile, setSaleProfile] = useState(false)
    const [loading, setLoading] = useState(true)
    const [path, setPath] = useState({ url: '', display: '' })

    const getCollectionProfile = (el, isCancelled) => {
        onSnapshot(doc(db, 'collectionWebProfiles', el), (doc) => {
            if (!isCancelled) {
                const data = doc.data()
                data.id = doc.id
                setProfile(data)
            }
        })
    }

    //Gets the permalink path
    useEffect(() => {
        let isCancelled = false
        if (pageDetails && profile?.id) {
            const q = query(collection(db, 'permalinks'), where('name', '==', 'item'))
            getDocs(q).then((docs) => {
                if (!isCancelled) {
    
                    let url = process.env.GATSBY_WEB_ADDRESS
                    if (pageDetails.parent) {
                        url += '/' + pageDetails.parent
                    }
                    
                    docs.forEach((doc) => {
                        const data = doc.data()
                        url += '/' + data.path
                        const display = url
                        url = display + pageDetails.slug + '?preview=true&id=' + profile.id
                        setPath({ url, display })
                    })
                }
            })
        }
        return () => {
            isCancelled = true
        }
    }, [pageDetails, profile])

    //Watches for if the page URL id changes
    useEffect(() => {
        let isCancelled = false
        getCollectionProfile(id, isCancelled)
        return () => {
            isCancelled = true
        }
    }, [id])

    //Watches if the web profile select changes
    useEffect(() => {
        let isCancelled = false
        if (profile.id === id && profileId !== id) {
            getCollectionProfile(profileId, isCancelled)
        }

        return () => {
            isCancelled = true
        }
    }, [profileId])

    //Watches if the profile is updated in general
    useEffect(() => {
        let isCancelled = false
        if (profile) {
            const docRef = doc(db, "collection", profile.item_id)
            onSnapshot(docRef, (doc) => {
                if (!isCancelled) {
                    setCollection(doc.data())
                }
            })
            
            if (profile?.saleProfileId) {
                const docRef = doc(db, "collectionSaleProfiles", profile.saleProfileId)
                getDoc(docRef).then((doc) => {
                    if (!isCancelled) {
                        setSaleProfile(doc.data())
                    }
                })
            } else {
                if (!isCancelled) {
                    setSaleProfile(false)
                }
            }
        }

        return () => {
            isCancelled = true
        }
    }, [profile])

    //Sets the Profile data
    useEffect(() => {
        if (collectionData && profile) {
            const webProfile = { ...profile }
            const item = { ...collectionData }

            let added = false
            if (item.date_added) {
                added = {
                    date_added: item.date_added,
                    added_by: item.added_by
                }
            }
            let published = false
            if (item.date_published) {
                published = {
                    date_published: item.date_published,
                    published_by: item.published_by
                }
            }
            let updated = false
            if (item.date_modified) {
                updated = {
                    date_modified: item.date_modified,
                    edited_by: item.edited_by
                }
            }

            dispatch(setPBDoc(profile.item_id))
            const extrasData = JSON.stringify({
                notes: item.notes || [],
                saleDetails: item.saleDetails || {},
            })
            dispatch(setPageExtras(extrasData))
            const detailsData = JSON.stringify({
                added,
                published,
                updated,
                saleProfileId: profile?.saleProfileId,
            })
            dispatch(setPageDetails(detailsData))

            // combine media (stored in collection) with featured image (media.set, stored in web profile)
            webProfile.media = {
                gallery: item.media.gallery,
                set: webProfile.media.set,
            }
            setProfileData(dispatch, webProfile.id, webProfile)
        }
    }, [collectionData, profile])

    //Sets the Tabs
    useEffect(() => {
        if (permissionsTier) {
            const tabs = [
                {
                    title: 'Identity',
                    content: <IdentityTab />
                },
                {
                    title: 'Web Profiles',
                    content: <WebProfilesTab />
                },
                {
                    title: 'Sale Profiles',
                    content: <SaleProfilesTab />
                },
                {
                    title: 'Ownership',
                    content: <HistoryTab />
                },
                
            ]

            if (isGrantedAccess('areaLeads', permissionsTier)) {
                tabs.push({
                    title: 'Leads',
                    content: <LeadsTab />
                })
            }
            
            tabs.push({
                title: 'Media',
                content: <MediaTab />
            },
            {
                title: 'Notes',
                content: <NotesTab type='item' />
            },
            {
                title: 'Options',
                content: <OptionsTab />
            })

            setTabs(tabs)
        }

        return () => {
            dispatch(resetPageData())
            dispatch(resetPageBuilder())
        }
    }, [permissionsTier])

    useEffect(() => {
        if (profileId === id) {
            setLoading(false)
        }
    }, [profileId])

    const onInputChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.target.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onSelectChange = (name, event) => {
        const detailsData = JSON.stringify({ [name]: event.value })
        dispatch(setPageDetails(detailsData))
        dispatch(saveToggle(true))
    }

    const onSubmit = async () => {
        dispatch(savingToggle(true))
        dispatch(saveToggle(false))

        //We're updating all post data
        const data = store.getState()

        updateActiveWebProfile(profileId, data.Pagebuilder, auth).then(() => {
            updateItem(collectionData, data.Pagebuilder, auth).then(() => {
                dispatch(savingToggle(false))
            })
        })
    }
    
    return (
        loading ?
            <Loading />
            :
            <Box>
                <Flex alignItems="center" justifyContent="flex-start">
                    <Heading variant="h4" as="h1" mr="lg" flexGrow={1}>
                        <EditableField
                            name="title"
                            placeholder='Enter Title'
                            {...register('title', { required: true })}
                            type='text'
                            defaultValue={pageDetails.title || null}
                            variant="contentEditableInput"
                            onChange={(e) => onInputChange('title', e)}
                            pl="0"
                            errors={errors}
                            errorMessage="Cannot be empty"
                        />
                    </Heading>
                    {/* <Flex flexGrow={1} height="2px" bg="greyLighter"></Flex> */}
                    {/* <Button variant='primarySmall' ml="md">Preview</Button> */}
                </Flex>
                <Box>
                    <Flex alignItems="center" justifyContent="flex-start" color="greyMedium" fontSize="md" mb="xxs">
                        <Text fontWeight="bold">Web Profile:</Text><Text pl="4px">{profile?.profile ?? 'Unknown'}</Text><Text pl="4px" color={profile.active ? 'green' : 'red'}>({profile.active ? 'Active' : 'Inactive'})</Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="flex-start" color="greyMedium" fontSize="md" mb="xxs">
                        <Text fontWeight="bold">Sale Profile:</Text><Text pl="4px" color={saleProfile ? null : 'red'}>{saleProfile?.title ?? 'None'}</Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="flex-start" color="greyMedium" fontSize="md">
                        <Text fontWeight="bold">Chassis Number:</Text><Text pl="4px">{characteristics?.chassis?.number ?? 'Unknown'}</Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="flex-start" color="greyMedium" fontSize="md">
                        <Text fontWeight="bold">Permalink:</Text>
                        <Link pl="xxs" href={path.url} color="greyMedium" fontSize="lg" target="_blank" sx={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faExternalLinkAlt} /></Link>
                        <Flex pl="xxs" mr='xxs' alignItems='center' flexGrow={1}>
                            {path.display}
                            <EditableField
                                name="slug"
                                placeholder='Enter Slug'
                                {...register('slug', { required: true })}
                                type='text'
                                value={pageDetails.slug || 'Add Slug'}
                                variant="contentEditableInput"
                                onChange={(e) => onInputChange('slug', e)}
                                pl="0"
                                errors={errors}
                                errorMessage="Cannot be empty"
                            />
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex flexGrow={1} flex={1} pr='xs' flexDirection='column'>
                            <Box variant="card" mt="sm" p="xs">
                                <TabContainer data={tabData} id={profileId} addLeadButtons  />
                            </Box>
                        </Flex>
                        {
                            isSetting ?
                                <ModuleSettings />
                                :
                                <RightSideBar onSelectChange={onSelectChange} onInputChange={onInputChange} onSubmit={onSubmit} section="collection" />
                        }
                    </Flex>
                </Box>
                {
                    isSaving ?
                        <PageLoader />
                        : null
                }
            </Box>
    );
}

function mapStateToProps(state) {
    return {
        pageDetails: state.Pagebuilder.details,
        auth: state.Auth.userVerified,
        isSaving: state.Savehandling.isSaving,
        profileId: state.Pagebuilder.profile.id,
        isSetting: state.Sidebar.isSetting,
        characteristics: state.Pagebuilder.extras && state.Pagebuilder.extras.characteristics,
        permissionsTier: state.User.permissionsTier
    }
}
export default connect(mapStateToProps)(CollectionModify)