import React, { useEffect, useState } from 'react'
import { Box } from 'rebass/styled-components'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const Pricing = ({ currency = '', amount='' }) => {
    const [symbol, setCurrency] = useState([])

    useEffect(() => {
        let isCancelled = false
        if (currency) {
            const docRef = doc(db, 'currencies', currency)
            getDoc(docRef).then((doc) => {
                if (!isCancelled) {
                    const currencyData = doc.data()
                    setCurrency(currencyData.symbol)
                }
            })
        }
        return () => {
            isCancelled = true
        }
    }, [currency])

    return (
        <Box as='span'>{symbol + parseFloat(amount).toFixed(2)}</Box>
    )
}

export default Pricing
