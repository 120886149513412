import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import { Textarea } from '@rebass/forms/styled-components'
import { Box, Button, Flex, Heading } from 'rebass/styled-components'
import BorderContainer from '../components/general/BorderContainer'
import DragHandle from '../components/general/DragHandle'
import { setPageExtras } from '../system/redux/reducers/pagebuilder'
import { saveToggle } from '../system/redux/reducers/savehandling'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const sortableOptions = {
    animation: 150,
    group: 'shared',
    handle: '.draggable-handle',
    ghostClass: 'sortable-ghost',
    fallbackClass: 'sortable-fallback',
    chosenClass: 'sortable-chosen',
}
const Sortable = styled(Box)`
   width: 100%;
   .sortable-chosen:not(.sortable-ghost) { 
       background-color: white;
    }
`

const KeyPoints = ({ dispatch, keypoints }) => {
    const [edit, setEdit] = useState(true)
    const [points, setPoints] = useState([])

    useEffect(() => {
        if (keypoints && keypoints.length) {
            const output = []
            keypoints.map((point, pointIndex) => {
                output.push({ id: pointIndex, point })
            })
            setPoints(output)
        } else {
            setPoints([])
        }
    }, [keypoints])

    const onInputChange = (key, event) => {
        const save = []
        const update = [...points]
        update[key].point = event.target.value
        update.map((point, pointIndex) => (
            save.push(point.point)
        ))
        const extrasData = JSON.stringify({ keypoints: save })
        dispatch(setPageExtras(extrasData))
        dispatch(saveToggle(true))
    }

    const createPoint = () => {
        const update = [...points]
        update.push({ id: points.length, point: '' })
        setPoints(update)
    }
    const removePoint = (key) => {
        const update = [...points]
        update.splice(key, 1)
        const save = update.map((point) => point.point)
        const extrasData = JSON.stringify({ keypoints: save })
        dispatch(setPageExtras(extrasData))
        dispatch(saveToggle(true))
    }

    return (
        <>
            <Flex justifyContent='space-between'>
                <Heading variant="h4" as="h4">Key Points</Heading>
                <Button variant='primaryTiny' onClick={() => setEdit(!edit)}>
                    { !edit ? 'Edit' : 'Lock'}
                </Button>
            </Flex>
            <Box pt='xs'>
                {
                    points.length ?
                        <Sortable>
                            <ReactSortable
                                list={points.map(x => ({ ...x, chosen: true }))} // solution
                                setList={(sortedList, element) => {
                                    if (element) {
                                        const save = []
                                        sortedList.map((item, index) => {
                                            save.push(item.point)
                                        })
                                        const extrasData = JSON.stringify({ keypoints: save })
                                        dispatch(setPageExtras(extrasData))
                                        dispatch(saveToggle(true))
                                    }
                                }}
                                {...sortableOptions}
                            >
                                {
                                    points.map((point, pointIndex) => (
                                        <BorderContainer key={point.id} mb='xxs' pb='sm'>
                                            <Flex pr='xxs'>
                                                <DragHandle />
                                                {
                                                    !edit ?
                                                        <Box lineHeight='22px'>
                                                            {point.point}
                                                        </Box> :
                                                        <Box width='100%'>
                                                            <Textarea value={point.point} rows="2" width='100%' sx={{ resize: 'vertical' }} onChange={(e) => onInputChange(pointIndex, e)} />
                                                        </Box>
                                                }
                                            </Flex>

                                            {
                                                edit ?
                                                    <Box onClick={() => removePoint(pointIndex)} fontSize='sm' color='greyMedium' sx={{
                                                        position: 'absolute',
                                                        top: 'xxs',
                                                        right: 'xxs',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            color: 'red',
                                                        },
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </Box> :
                                                    null
                                            }
                                        </BorderContainer>
                                    ))
                                }
                            </ReactSortable>
                        </Sortable> :
                        null
                }
                {
                    edit ?
                        <Box onClick={createPoint} sx={{
                            color: 'greyLight',
                            '&:hover': {
                                backgroundColor: 'redOff',
                                cursor: 'pointer',
                                color: 'greyMedium',
                            },
                        }}>
                            <BorderContainer mb='xxs' pb='sm'>
                                <Flex width='100%' justifyContent='center' fontSize='xxxl'>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Flex>
                            </BorderContainer>
                        </Box> :
                        null
                }
            </Box>
        </>
    )
}

function mapStateToProps(state) {
    return {
        keypoints: state.Pagebuilder.extras.keypoints,
    }
}

export default connect(mapStateToProps)(KeyPoints)
