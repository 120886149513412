import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Heading } from 'rebass/styled-components'
import LeadsTable from '../leads/LeadsTable'

const LeadsTab = ({ dispatch, modelId }) => {
    const [isLoading, setIsLoading] = useState(true)

    // don't load anything until we have a modelId (mostly for the LeadsTable having incorrect data for filtering)
    useEffect(() => {
        if (modelId) {
            setIsLoading(false)
        } 
    }, [modelId])

    return (
        <>
            <Flex justifyContent='space-between'>
                <Heading variant="h4" as="h4">Leads</Heading>
            </Flex>
            <Box pt='xs'>
                {
                    isLoading 
                        ? null
                        : <LeadsTable modelId={modelId}></LeadsTable>
                }
            </Box>
        </>
    )
}

function mapStateToProps(state) {
    return {
        modelId: state.Pagebuilder.extras.characteristics.model,
    }
}

export default connect(mapStateToProps)(LeadsTab)
